import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';

import { About } from "./routes/About";
import { Jobs } from './routes/Jobs';
import { JobDetails } from './routes/JobDetails';
import { Home } from './routes/Home';
import { Talent } from "./routes/Talent";
import { JoinUs } from "./routes/JoinUs";
import { Locations } from "./routes/Locations";
import { Terms } from "./routes/Terms";
import { Privacy } from "./routes/Privacy";

import { Layout } from './components/Layout/Layout';
import { instance } from './utils/getServicesURL';

import './styles/App.css';

function App(messages) {

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [isActiveLink, setActive] = useState("Home");
  const [isScroll, setScroll] = useState(false);

  const setLink = (linkName) => {
    setActive(linkName);
  }

  const setNeedScroll = (isScroll) => {
    setScroll(isScroll);
  }

  return (
    <div className={isMobile ? "App App-mobile" : "App"} id="App">
        <Routes>
            <Route path="/" element={<Layout isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} instance={instance} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}>

            <Route index element={<Home messages={messages} instance={instance} isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/about_us" element={<About isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/jobs" element={<Jobs instance={instance} isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/job_details/:slug" element={<JobDetails instance={instance} isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/talent" element={<Talent instance={instance} isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/join_us" element={<JoinUs instance={instance} isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/terms" element={<Terms isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/privacy" element={<Privacy isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>
            <Route path="/locations" element={<Locations isMobile={isMobile} isActiveLink={isActiveLink} setLink={setLink} setNeedScroll={setNeedScroll} isScroll={isScroll}/>}/>

            <Route
              path="*"
              element={
                <main className="App">
                  <div className="content">
                    <h2>
                      <FormattedMessage id="app.wildcard.header"
                          defaultMessage="There's nothing here!"
                          description="Empty page"
                          values={{what: 'react-intl'}}
                      />
                    </h2>
                    <Link to="/" style={{color: "white"}}>Beehire</Link>
                  </div>
                </main>
              }
            />
            </Route>
        </Routes>
    </div>
  );
}

export default App;
