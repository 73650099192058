import React from "react";
import { Header } from "../Header/Header"
import { Footer } from "../Footer/Footer"
import { Outlet } from "react-router-dom";

export const Layout = (props) => {
  return (
    <>
      <Header isMobile={props.isMobile} isActiveLink={props.isActiveLink} isScroll={props.isScroll} instance={props.instance}/>
        <Outlet isActiveLink={props.isActiveLink} setLink={props.setLink} setNeedScroll={props.setNeedScroll} isScroll={props.isScroll}/>
      <Footer isMobile={props.isMobile} instance={props.instance} isScroll={props.isScroll}/>
    </>
  )
}