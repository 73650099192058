export const emailCheck = (email) => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email !== "" && email.match(mailformat))
    {
        console.log('valid email');
        return true;
    }
    else {
        console.log("not correct email format");
        return false;
    }
}