import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { IntlProvider } from 'react-intl';
import { BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import messages_zh from "./lang/zh.json";
import messages_en from "./lang/en.json";

const messages = {
  'zh': messages_zh,
  'en': messages_en
};
// const language = navigator.language.split(/[-_]/)[0];  // language without region code

const language = 'en';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <IntlProvider locale = {language} messages = {messages[language]}>
        <App/>
      </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
