import React from "react";
import './Header.css';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Logo from '../../images/light-bg-selected.png';
import MediaQuery from 'react-responsive';
import Navbar from "../Navbar/Navbar";

export const Header = (props) => {

    return (
        <div className={props.isMobile? "header-mobile" : "header"}>
            <Link to="/">
                <img className={props.isMobile? "header-logo-mobile" : "header-logo"} src={Logo} alt="Beehire"/>
            </Link>
            <MediaQuery minWidth="480px">
                <nav className="routes">
                    <Link className={props.isActiveLink === "Jobs" ? "header-link header-selected" : "header-link"} to="/jobs">
                        <FormattedMessage id="app.header.jobs"
                            defaultMessage="Jobs"
                            description="Jobs"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className={props.isActiveLink === "Talent" ? "header-link header-selected" : "header-link"} to="/talent">
                        <FormattedMessage id="app.header.talent"
                            defaultMessage="Talent"
                            description="Talent"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className={props.isActiveLink === "AboutUs" ? "header-link header-selected" : "header-link"} to="/about_us">
                        <FormattedMessage id="app.header.about"
                            defaultMessage="About"
                            description="About"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className={props.isActiveLink === "JoinUs" ? "header-link header-selected" : "header-link"} to="/join_us">
                        <FormattedMessage id="app.header.join_us"
                            defaultMessage="Join Us"
                            description="Join Us"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className={props.isActiveLink === "Locations" ? "header-link header-selected" : "header-link"} to="/locations">
                        <FormattedMessage id="app.header.locations"
                            defaultMessage="Locations"
                            description="Locations"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                </nav>
            </MediaQuery>
            <MediaQuery maxWidth="480px">
                <Navbar instance={props.instance} isMobile={props.isMobile}/>
            </MediaQuery>
        </div>
    );
}