import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, ValidationError } from '@formspree/react';
import './ContactForm.css';

export default function ContactForm(props) {
  const [state, handleSubmit] = useForm("mrgokgzb");
  if (state.succeeded) {
    document.getElementById('name').value = '';
    document.getElementById('company').value = '';
    document.getElementById('email').value = '';
    document.getElementById('phone').value = '';
    document.getElementById('message').value = '';
    props.closeForm();
  }
  return (
      <form onSubmit={handleSubmit}>
        <div className="form-content-wrapper">
          <div className='form-content'>
              <div className={props.isMobile? "lightbox-title lightbox-title-mobile" : "lightbox-title"}>
                <FormattedMessage id="app.contact.lightbox.alert.title"
                  defaultMessage="Contact Alert title"
                  description="Contact Alert description"
                  values={{what: 'react-intl'}}/>
              </div>
          </div>

          <div className='form-content'>
            <label htmlFor="name" className='lightbox-label'>Full Name<div className="mandatory">*</div></label>
            <div className='lightbox-input-wrapper'>
              <input id="name" type="text" name="name" className="lightbox-input" required />
              <ValidationError prefix="Name" field="name" errors={state.errors} />
            </div>
          </div>

          <div className='form-content'>
            <label htmlFor="company" className='lightbox-label'>Company Name<div className="mandatory">*</div></label>

            <div className='lightbox-input-wrapper'>
              <input id="company" type="text" name="company" className="lightbox-input" required />
              <ValidationError prefix="Company" field="company" errors={state.errors} />
            </div>
          </div>

          <div className='form-content'>
            <label htmlFor="email" className='lightbox-label'>Email Address<div className="mandatory">*</div></label>

            <div className='lightbox-input-wrapper'>
              <input id="email" type="email" name="email" className="lightbox-input" required />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
            </div>
          </div>

          <div className='form-content'>
            <label htmlFor="phone" className='lightbox-label'>Phone Number<div className="mandatory">*</div></label>

            <div className='lightbox-input-wrapper'>
              <input id="phone" type="tel" name="phone" className="lightbox-input" required/>
              <ValidationError prefix="Phone" field="phone" errors={state.errors} />
            </div>
          </div>

          <div className='form-content'>
            <label htmlFor="message" className='lightbox-label'>Message</label>

            <div className='lightbox-input-wrapper'>
              <textarea id="message" name="message" className="lightbox-input-area"/>
              <ValidationError prefix="Message" field="message" errors={state.errors}/>
            </div>
          </div>

          <button className="lightbox-alert-submit" type="submit">Submit</button>
        </div>
    </form>
  );
}