import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Button } from '../components/Button/Button';
import { ImageTextBlock } from '../components/ImageTextBlock/ImageTextBlock';
import { JobsSlider } from '../components/JobsSlider/JobsSlider';

import HomeBannerSection1Img from '../images/home_banner_1_image.png';
import HomeBannerSection1Img_m from '../images/mobile/home_banner_1_image.png';
import BannerBackground from '../images/home_banner_bg.png';
import BannerBackground_m from '../images/mobile/home_banner_bg.png';
import HomeBannerSection2Img from '../images/home_banner_2_image.png';
import HomeBannerSection2Img_m from '../images/mobile/home_banner_2_image.png';
import HomeBannerSection4Background from '../images/homepage-section-4-bg.png';
import HomeBannerSection4Background_m from '../images/mobile/homepage-section-4-bg.png';
import HomepageSection5Item1Image from '../images/homepage-section-5-item-1.png';
import HomepageSection5Item2Image from '../images/homepage-section-5-item-2.png';
import HomepageSection5Item3Image from '../images/homepage-section-5-item-3.png';
import HomepageSection5Item4Image from '../images/homepage-section-5-item-4.png';
import LogoCheckbox from '../images/logo_checkbox.png';
import ArrowRight from '../images/arrow_right.png';
import '../styles/App.css';

export const Home = (props) => {

    const [jobs, setJobs] = useState([]);
    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        props.setLink("Home");
        try {
            props.instance.get(`/jobs/search`, { params: { job_status: 1 }}).then(response =>
            {
                if (mounted)
                {
                    let result = [];
                    for (let i = 0; i < 10; i ++)
                    {
                        result.push(response.data.data[i]);
                    };
                    setJobs(result);
                }
            })
        } catch (err) {
            console.error(err);
        }
        return () => mounted = false;
    }, []);


    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            {props.isMobile?
                <>
                    <div className='home-content-background home-content-background-mobile'>
                        <img src={BannerBackground_m} />
                    </div>
                    <div className="content-wrapper-mobile homepage-content-1-wrapper">
                        <ImageTextBlock
                            title={
                                <FormattedMessage id="app.homepage.content_1_m.title"
                                defaultMessage="Home Page"
                                description="Mobile content 1 Title"
                                values={{what: 'react-intl'}}/>
                                }
                            content={
                                <div>
                                    <FormattedMessage id="app.homepage.content_1_m.content"
                                        defaultMessage="Home Page"
                                        description="Mobile content 1 Content"
                                        values={{what: 'react-intl'}}/>
                                </div>
                            }
                            reverse
                        />
                        <div className="button-wrapper-mobile">
                            <div className='button-mobile'>
                                <Button
                                    ButtonTextId="app.homepage.content_1.button_1"
                                    DefaultText="content 1 button 1"
                                    ButtonDesc="Find a job"
                                    ButtonLink="/jobs"
                                    ButtonStyle="primary"
                                    />
                            </div>

                            <div className='button-mobile'>
                                <Button
                                    ButtonTextId="app.homepage.content_1.button_2"
                                    DefaultText="content 1 button 2"
                                    ButtonDesc="Find a staff"
                                    ButtonLink="/talent"
                                    ButtonStyle="default"
                                    />
                            </div>
                        </div>
                        <div className='home-content-content-1-logo-mobile'>
                            <img src={HomeBannerSection1Img_m} />
                        </div>
                    </div>


                    <div className="content-wrapper-mobile">
                        <div className='homepage-content-2-mobile'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.homepage.content_2.title"
                                    defaultMessage="Home Page"
                                    description="content 2 Title"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div className='homepage-block-content-2-mobile'>
                                        <FormattedMessage id="app.homepage.content_2.content_m"
                                            defaultMessage="Home Page"
                                            description="content 2 Content"
                                            values={{what: 'react-intl'}} />
                                        <ul className='content-list'>
                                            <li className='content-list-li'>
                                                <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                                <div className='content-list-text'>
                                                    <FormattedMessage id="app.homepage.content_2.content_list_1"
                                                    defaultMessage="List 1"
                                                    description="content 2 Content list 1"
                                                    values={{what: 'react-intl'}} />
                                                </div>
                                            </li>
                                            <li className='content-list-li'>
                                                <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                                <div className='content-list-text'>
                                                    <FormattedMessage id="app.homepage.content_2.content_list_2"
                                                    defaultMessage="List 2"
                                                    description="content 2 Content list 2"
                                                    values={{what: 'react-intl'}} />
                                                </div>
                                            </li>
                                            <li className='content-list-li'>
                                                <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                                <div className='content-list-text'>
                                                    <FormattedMessage id="app.homepage.content_2.content_list_3"
                                                    defaultMessage="List 3"
                                                    description="content 2 Content list 3"
                                                    values={{what: 'react-intl'}} />
                                                </div>
                                            </li>
                                            <li className='content-list-li'>
                                                <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                                <div className='content-list-text'>
                                                    <FormattedMessage id="app.homepage.content_2.content_list_4"
                                                    defaultMessage="List 4"
                                                    description="content 2 Content list 4"
                                                    values={{what: 'react-intl'}} />
                                                </div>
                                            </li>
                                        </ul>
                                        <div className ="homepage-content-link-footer homepage-content-2-footer homepage-content-2-footer-mobile">
                                            <Link  to="/about_us">
                                                <FormattedMessage id="app.homepage.know_more"
                                                    defaultMessage="About us"
                                                    description="About us"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </Link>
                                            <img src={ArrowRight} alt="about us"/>
                                        </div>
                                    </div>
                                }
                                overwriteImageBlock={
                                    <div className='homepage-section-2-imageBlock-mobile'>
                                        <img src={HomeBannerSection2Img_m} alt="Beehire"/>
                                    </div>
                                }
                            />
                        </div>
                    </div>

                    <div className='content-wrapper-mobile'>
                        <div className="homepage-content-3-mobile">
                            <div className='title-mobile'>
                                <FormattedMessage id="app.homepage.jobpost"
                                    defaultMessage="Recently Job Post"
                                    description="Recently Job Post"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className="homepage-content-3-slider-mobile">
                                <JobsSlider isMobile={props.isMobile} sliderData={jobs}/>
                            </div>
                            <div className='homepage-content-link-footer homepage-content-3-footer'>
                                <Link  to="/jobs">
                                    <FormattedMessage id="app.homepage.more_jobs"
                                        defaultMessage="View More Jobs"
                                        description="View More Jobs"
                                        values={{what: 'react-intl'}}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='content-wrapper-mobile align-center'>
                        <div className='home-content-4-background-mobile'>
                            <img src={HomeBannerSection4Background_m} />
                        </div>
                        <div className="homepage-content-4-mobile">
                            <div className='homepage-content-4-title-mobile'>
                                <FormattedMessage id="app.homepage.content_4.title"
                                        defaultMessage="Join us to bring Energetic Work Forces to the world!"
                                        description="Join us to bring Energetic Work Forces to the world!"
                                        values={{what: 'react-intl'}}
                                    />
                            </div>
                            <div className='homepage-content-4-content-mobile'>
                                <FormattedMessage id="app.homepage.content_4.content"
                                        defaultMessage="Join us to bring Energetic Work Forces to the world!"
                                        description="Join us to bring Energetic Work Forces to the world!"
                                        values={{what: 'react-intl'}}
                                    />
                            </div>
                            <div className='homepage-content-4-button-mobile'>
                                <div className='button-mobile'>
                                    <Button
                                        ButtonTextId="app.homepage.content_4.button"
                                        DefaultText="content 4 button"
                                        ButtonDesc="Join Our Team"
                                        ButtonLink="/join_us"
                                        ButtonStyle="primary"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            :
                <>
                    <div className='home-content-background'>
                        <img src={BannerBackground} />
                    </div>
                    <div className="content-wrapper">
                        <div className='homepage-content-1'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.homepage.content_1.title"
                                    defaultMessage="Home Page"
                                    description="content 1 Title"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div className="homepage-content">
                                        <FormattedMessage id="app.homepage.content_1.content"
                                            defaultMessage="Home Page"
                                            description="content 1 Content"
                                            values={{what: 'react-intl'}}

                                        />
                                        {/* <div className="homepage-content-1-footer">
                                            <FormattedMessage id="app.homepage.content_1_m.footer"
                                                defaultMessage="Home Page"
                                                description="Mobile content 1 Footer"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div> */}

                                        <div className="button-wrapper">
                                        <Button
                                            ButtonTextId="app.homepage.content_1.button_1"
                                            DefaultText="content 1 button 1"
                                            ButtonDesc="Find a job"
                                            ButtonLink="/jobs"
                                            ButtonStyle="primary"
                                            />
                                        <Button
                                            ButtonTextId="app.homepage.content_1.button_2"
                                            DefaultText="content 1 button 2"
                                            ButtonDesc="Find a staff"
                                            ButtonLink="/talent"
                                            ButtonStyle="default"
                                            />
                                        </div>
                                    </div>
                                }
                                reverse
                                overwriteImageBlock={
                                    <div className='homepage-section-1-imageBlock'>
                                        <img src={HomeBannerSection1Img} alt="Beehire"/>
                                    </div>
                                }
                            />
                        </div>
                    </div>

                    <div className='content-wrapper homepage-content-2'>
                        <div className='homepage-content-2'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.homepage.content_2.title"
                                    defaultMessage="Home Page"
                                    description="content 2 Title"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                <div className='homepage-block-content-2'>
                                    <FormattedMessage id="app.homepage.content_2.content"
                                        defaultMessage="Home Page"
                                        description="content 2 Content"
                                        values={{what: 'react-intl'}} />
                                    <ul className='content-list'>
                                        <li className='content-list-li'>
                                            <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                            <div className='content-list-text'>
                                                <FormattedMessage id="app.homepage.content_2.content_list_1"
                                                defaultMessage="List 1"
                                                description="content 2 Content list 1"
                                                values={{what: 'react-intl'}} />
                                            </div>
                                        </li>
                                        <li className='content-list-li'>
                                            <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                            <div className='content-list-text'>
                                                <FormattedMessage id="app.homepage.content_2.content_list_2"
                                                defaultMessage="List 2"
                                                description="content 2 Content list 2"
                                                values={{what: 'react-intl'}} />
                                            </div>
                                        </li>
                                        <li className='content-list-li'>
                                            <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                            <div className='content-list-text'>
                                                <FormattedMessage id="app.homepage.content_2.content_list_3"
                                                defaultMessage="List 3"
                                                description="content 2 Content list 3"
                                                values={{what: 'react-intl'}} />
                                            </div>
                                        </li>
                                        <li className='content-list-li'>
                                            <img className="content-list-image" src={LogoCheckbox} alt="checkbox"/>
                                            <div className='content-list-text'>
                                                <FormattedMessage id="app.homepage.content_2.content_list_4"
                                                defaultMessage="List 4"
                                                description="content 2 Content list 4"
                                                values={{what: 'react-intl'}} />
                                            </div>
                                        </li>
                                    </ul>
                                    <div className ="homepage-content-link-footer homepage-content-2-footer">
                                        <Link  to="/about_us">
                                            <FormattedMessage id="app.homepage.know_more"
                                                defaultMessage="About us"
                                                description="About us"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                        <img src={ArrowRight} alt="about us"/>
                                    </div>
                                </div>
                                }
                                overwriteImageBlock={
                                    <div className='homepage-section-2-imageBlock'>
                                        <img src={HomeBannerSection2Img} alt="Beehire"/>
                                    </div>
                                }
                            />
                        </div>
                    </div>

                    <div className='content-wrapper homepage-content-5-wrapper'>
                        <div className='homepage-content-5'>
                            <div className='title'>
                                <FormattedMessage id="app.homepage.content_5.title"
                                    defaultMessage="Reimagining Recruitment with Beehire"
                                    description="Reimagining Recruitment with Beehire"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='homepage-content-5-subtitle'>
                                <FormattedMessage id="app.homepage.content_5.subtitle"
                                    defaultMessage="Reimagining Recruitment with Beehire"
                                    description="Reimagining Recruitment with Beehire"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className='homepage-content-5-list'>
                                <div className='homepage-content-5-item'>
                                    <div className='item-image'>
                                        <img src={HomepageSection5Item1Image} />
                                    </div>
                                    <div className='item-title'>
                                        <FormattedMessage id="app.homepage.content_5.item_1.title"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                    <div className='item-content'>
                                        <FormattedMessage id="app.homepage.content_5.item_1.content"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>

                                <div className='homepage-content-5-item'>
                                    <div className='item-image'>
                                        <img src={HomepageSection5Item2Image} />
                                    </div>
                                    <div className='item-title'>
                                        <FormattedMessage id="app.homepage.content_5.item_2.title"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                    <div className='item-content'>
                                        <FormattedMessage id="app.homepage.content_5.item_2.content"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>

                                <div className='homepage-content-5-item'>
                                    <div className='item-image'>
                                        <img src={HomepageSection5Item3Image} />
                                    </div>
                                    <div className='item-title'>
                                        <FormattedMessage id="app.homepage.content_5.item_3.title"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                    <div className='item-content'>
                                        <FormattedMessage id="app.homepage.content_5.item_3.content"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>

                                <div className='homepage-content-5-item'>
                                    <div className='item-image'>
                                        <img src={HomepageSection5Item4Image} />
                                    </div>
                                    <div className='item-title'>
                                        <FormattedMessage id="app.homepage.content_5.item_4.title"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                    <div className='item-content'>
                                        <FormattedMessage id="app.homepage.content_5.item_4.content"
                                            defaultMessage="Reimagining Recruitment with Beehire"
                                            description="Reimagining Recruitment with Beehire"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-wrapper homepage-content-3-wrapper">
                        <div className='homepage-content-3'>
                            <div className='title'>
                                <FormattedMessage id="app.homepage.jobpost"
                                    defaultMessage="Recently Job Post"
                                    description="Recently Job Post"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='homepage-content-3-slider'>
                                <JobsSlider sliderData={jobs} isMobile={props.isMobile}/>
                            </div>
                            <div className='homepage-content-link-footer homepage-content-3-footer'>
                                <Link  to="/jobs">
                                        <FormattedMessage id="app.homepage.more_jobs"
                                            defaultMessage="View More Jobs"
                                            description="View More Jobs"
                                            values={{what: 'react-intl'}}
                                        />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='content-wrapper homepage-content-4'>
                        <div className='homepage-content-4-background'>
                            <img src={HomeBannerSection4Background} alt="section 5 background"/>
                        </div>
                        <div className='homepage-content-4-wrapper'>
                            <div className='homepage-content-4-title'>
                                <FormattedMessage id="app.homepage.content_4.title"
                                    defaultMessage="Join Us"
                                    description="Join Us"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='homepage-content-4-content'>
                                <FormattedMessage id="app.homepage.content_4.content"
                                    defaultMessage="Join Us"
                                    description="Join Us"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='homepage-content-4-button'>
                                <Link  to="/join_us" className="button-link primary">
                                    <FormattedMessage id="app.homepage.content_4.button"
                                        defaultMessage="Join Us"
                                        description="Join Us"
                                        values={{what: 'react-intl'}}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            }
        </main>
    );
}