import axios from 'axios';

const token = process.env.REACT_APP_API_TOKEN;

export const instance = axios.create({
    baseURL: `https://api.recruitcrm.io/v1/`,
    timeout: 5000,
    headers: { "Authorization": `Bearer ${token}`, "Content-type": "application/json" }
});

