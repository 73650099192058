import React,{ useState }from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { RiArrowRightSLine } from 'react-icons/ri'

import './Footer.css';
import LinkInLogo from '../../images/linkin_logo.png';
import AwardLogo from '../../images/hr_award.png';
import JobsAlertLightbox  from '../JobsAlertLightbox/JobsAlertLightbox';

export const Footer = (props) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [canUpload, setCanUpload] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const resetSelectedTags = () => {
    }

    const toggleLightbox = (isViewmore) => {
        if (isOpen)
        {
            setOpen(false);
            setCanUpload(false);
            setSelectedJob({});
        }
        else
        {
            window.scrollTo({top: 0, behavior: 'smooth'});
            if (isViewmore)
            {
                setCanUpload(false);
            }
            else
            {
                setCanUpload(true);
            }
            setOpen(true);
            setSelectedJob();
        }
    }

    const addressIframeHK = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.010654235833!2d114.17233141532735!3d22.277586249347657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404005b9d751aa7%3A0x27c09853391a49d0!2z54Gj5LuU6LuS5bC86Kmp6YGTMjAw6Jmf5oGG55Sf54Gj5LuU5aSn5buI!5e0!3m2!1szh-TW!2shk!4v1679278855687!5m2!1szh-TW!2shk";
    const addressLinkHK = "https://goo.gl/maps/TZU9mN78JxBB4LSD6";

    return (
        <div className={props.isMobile? "footer-mobile" :"footer"}>
            <div className={props.isMobile? "footer-wrapper-mobile" : 'footer-wrapper'}>
                <div className='linkin-page'>
                    <img className="linkin-logo" src={LinkInLogo} alt="Linkin"/>
                    <a target="_blank" href="https://hk.linkedin.com/company/beehire-personnel-limited">
                        <FormattedMessage id="app.footer.linkin"
                            defaultMessage="Follow us"
                            description="Follow us"
                            values={{what: 'react-intl'}}
                        />
                    </a>
                    <RiArrowRightSLine className='footer-arrow'/>
                </div>

                {props.isMobile ?
                    <>
                        <div className='roadmap-wrapper-mobile'>
                            <div className='column-mobile'>
                                <ul className='footer-menu'>
                                    <li>
                                        <Link  to="/jobs">
                                            <FormattedMessage id="app.footer.jobs"
                                                defaultMessage="Job Seeking"
                                                description="Job Seeking"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/talent">
                                            <FormattedMessage id="app.footer.talent"
                                                defaultMessage="Talent Seeking"
                                                description="Talent Seeking"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/about_us">
                                            <FormattedMessage id="app.footer.about"
                                                defaultMessage="All about us"
                                                description="All about us"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/join_us">
                                            <FormattedMessage id="app.footer.join_us"
                                                defaultMessage="Join Our Team"
                                                description="Join Our Team"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/locations">
                                            <FormattedMessage id="app.footer.locations"
                                                defaultMessage="Locations"
                                                description="Locations"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='column-mobile'>
                                <ul className='footer-menu'>
                                    <li>
                                        <Link  to="#" onClick={()=> toggleLightbox()}>
                                            <FormattedMessage id="app.footer.summit_cv"
                                                defaultMessage="Summit CV"
                                                description="Summit CV"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/terms">
                                            <FormattedMessage id="app.footer.terms"
                                                defaultMessage="Term & Conditions"
                                                description="Term & Conditions"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/privacy">
                                            <FormattedMessage id="app.footer.privacy"
                                                defaultMessage="Privacy Statement"
                                                description="Privacy Statement"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <img src={AwardLogo} alt="HK HRAward" className="footer-award footer-award-mobile"/>
                        </div>
                        <div className='footer-divide'></div>

                        <div className='location-wrapper-mobile'>
                            <div className='location-title'>
                                <FormattedMessage id="app.footer.contact"
                                    defaultMessage="Contact us by"
                                    description="Contact us by"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='location-contact-mobile'>
                                <div className='location-contact-block-mobile'>
                                    <FormattedMessage id="app.footer.contact.office"
                                        defaultMessage="Hong Kong Office"
                                        description="Hong Kong Office"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='location-contact-block-mobile'>
                                    + 852 5707 1610
                                </div>
                                <div className='location-contact-block-mobile'>
                                    <a className="location-email" href="mailto:info@beehire.co">info@beehire.co</a>
                                    <br/>
                                    <br/>
                                    <a className="location-address" target="_blank" href={addressLinkHK}>
                                        <FormattedMessage id="app.footer.address"
                                            defaultMessage="Hong Kong Office"
                                            description="Hong Kong Office"
                                            values={{what: 'react-intl'}}
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className='footer-license-mobile'>
                                <div>© 2022 by Beehire Personnel Limited.</div>
                                <div>EA License No.:57975</div>
                            </div>
                        </div>

                        <JobsAlertLightbox
                            isOpen={isOpen}
                            canUpload={canUpload}
                            selectedJob={selectedJob}
                            selectedTags={selectedTags}
                            toggleLightbox={toggleLightbox}
                            resetSelectedTags={resetSelectedTags}
                            instance={props.instance}
                            isMobile={props.isMobile}
                        />
                    </>
                :
                    <>
                        <div className='roadmap-wrapper'>
                            <div className='column'>
                                <ul className='footer-menu'>
                                    <li>
                                        <Link  to="/jobs">
                                            <FormattedMessage id="app.footer.jobs"
                                                defaultMessage="Job Seeking"
                                                description="Job Seeking"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/talent">
                                            <FormattedMessage id="app.footer.talent"
                                                defaultMessage="Talent Seeking"
                                                description="Talent Seeking"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/about_us">
                                            <FormattedMessage id="app.footer.about"
                                                defaultMessage="All about us"
                                                description="All about us"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/join_us">
                                            <FormattedMessage id="app.footer.join_us"
                                                defaultMessage="Join Our Team"
                                                description="Join Our Team"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/locations">
                                            <FormattedMessage id="app.footer.locations"
                                                defaultMessage="Locations"
                                                description="Locations"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='column'>
                                <ul className='footer-menu'>
                                    <li>
                                        <Link to="#" onClick={()=> toggleLightbox()}>
                                            <FormattedMessage id="app.footer.summit_cv"
                                                defaultMessage="Summit CV"
                                                description="Summit CV"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/terms">
                                            <FormattedMessage id="app.footer.terms"
                                                defaultMessage="Term & Conditions"
                                                description="Term & Conditions"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to="/privacy">
                                            <FormattedMessage id="app.footer.privacy"
                                                defaultMessage="Privacy Statement"
                                                description="Privacy Statement"
                                                values={{what: 'react-intl'}}
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='column'>
                                <div className='location-title'>
                                    <FormattedMessage id="app.footer.contact"
                                        defaultMessage="Contact us by"
                                        description="Contact us by"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='location-contact'>
                                    <div className='location-contact-block'>
                                        <FormattedMessage id="app.footer.contact.office"
                                            defaultMessage="Hong Kong Office"
                                            description="Hong Kong Office"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                    <div className='location-contact-block'>
                                        + 852 5707 1610
                                    </div>
                                </div>
                                <div className='location-map'>
                                    <div className='location-contact-block'>
                                        <iframe src={addressIframeHK} width="120" height="120" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className='location-contact-block'>
                                        <a className="location-email" href="mailto:info@beehire.co">info@beehire.co</a>
                                        <br/>
                                        <br/>
                                        <a className="location-address" target="_blank" href={addressLinkHK}>
                                            <FormattedMessage id="app.footer.address"
                                                defaultMessage="Hong Kong Office"
                                                description="Hong Kong Office"
                                                values={{what: 'react-intl'}}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <img src={AwardLogo} alt="HK HRAward" className="footer-award"/>
                            </div>
                        </div>
                        <div className='footer-license'>
                            <div>© 2022 by Beehire Personnel Limited.</div>
                            <div>EA License No.:57975</div>
                        </div>

                        <JobsAlertLightbox
                            isOpen={isOpen}
                            canUpload={canUpload}
                            selectedJob={selectedJob}
                            selectedTags={selectedTags}
                            toggleLightbox={toggleLightbox}
                            resetSelectedTags={resetSelectedTags}
                            instance={props.instance}
                            isMobile={props.isMobile}
                        />
                    </>
                }
            </div>
        </div>
    );
}