import React, { useState } from "react";
import './Navbar.css'
import { Link } from 'react-router-dom';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FormattedMessage } from 'react-intl';
import JobsAlertLightbox  from '../JobsAlertLightbox/JobsAlertLightbox';

import Logo from '../../images/menu-logo.png';
import LinkInLogo from '../../images/linkin_logo.png';

const Navbar = (props) => {

    // to change burger classes
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("menu hidden")
    const [menu_text_class, setMenuTextClass] = useState("menu-text visible")
    const [menu_cross_class, setMenuCrossClass] = useState("menu-cross hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(false)

    // toggle burger menu change
    const updateMenu = (isSummit) => {
        if(!isMenuClicked) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("menu visible")
            setMenuTextClass("menu-text hidden")
            setMenuCrossClass("menu-cross visible")
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("menu hidden")
            setMenuTextClass("menu-text visible")
            setMenuCrossClass("menu-cross hidden")
        }
        setIsMenuClicked(!isMenuClicked)

        if (isSummit)
        {
            toggleLightbox();
        }
    }

    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [canUpload, setCanUpload] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const resetSelectedTags = () => {
    }

    const toggleLightbox = (isViewmore) => {
        if (isOpen)
        {
            setOpen(false);
            setCanUpload(false);
            setSelectedJob({});
        }
        else
        {
            window.scrollTo({top: 0, behavior: 'smooth'});
            if (isViewmore)
            {
                setCanUpload(false);
            }
            else
            {
                setCanUpload(true);
            }
            setOpen(true);
            setSelectedJob();
        }
    }

    return(
        <>
            <nav className="nav" onClick={() => updateMenu()}>
                <div className="burger-menu" >
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                </div>
                <div className={menu_text_class}>menu</div>
            </nav>
            <div className={menu_class}>
                <div className="menu-header">
                    <Link to="/" onClick={() => updateMenu()}><img className="menu-logo" src={Logo} alt="Beehire"/></Link>
                    <div className={menu_cross_class} onClick={() => updateMenu()}><AiOutlineCloseCircle/></div>
                </div>
                <div className="menu-link-wrapper">
                    <Link className ="menu-link" to="/jobs" onClick={() => updateMenu()}>
                        <FormattedMessage id="app.menu.jobs"
                            defaultMessage="Jobs"
                            description="Jobs"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className ="menu-link" to="/talent" onClick={() => updateMenu()}>
                        <FormattedMessage id="app.menu.talent"
                            defaultMessage="Talent"
                            description="Talent"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className ="menu-link" to="/about_us" onClick={() => updateMenu()}>
                        <FormattedMessage id="app.menu.about"
                            defaultMessage="About"
                            description="About"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className ="menu-link" to="/join_us" onClick={() => updateMenu()}>
                        <FormattedMessage id="app.menu.join_us"
                            defaultMessage="Join Us"
                            description="Join Us"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                    <Link className ="menu-link" to="/locations" onClick={() => updateMenu()}>
                        <FormattedMessage id="app.menu.locations"
                            defaultMessage="Locations"
                            description="Locations"
                            values={{what: 'react-intl'}}
                        />
                    </Link>
                </div>

                <div className='menu-footer'>
                    <div className='linkin-page'>
                        <img className="linkin-logo" src={LinkInLogo} alt="Linkin"/>
                        <a target="_blank" href="https://hk.linkedin.com/company/beehire-personnel-limited">
                            <FormattedMessage id="app.menu.linkin"
                                defaultMessage="Follow us"
                                description="Follow us"
                                values={{what: 'react-intl'}}
                            />
                        </a>
                    </div>

                    <ul className='menu-footer-wrapper'>
                        <li>
                            <Link to="#" onClick={() => updateMenu(true)}>
                                <FormattedMessage id="app.footer.summit_cv"
                                    defaultMessage="Summit CV"
                                    description="Summit CV"
                                    values={{what: 'react-intl'}}
                                />
                            </Link>
                        </li>
                        <li>
                            <Link to="/terms" onClick={() => updateMenu()}>
                                <FormattedMessage id="app.footer.terms"
                                    defaultMessage="Term & Conditions"
                                    description="Term & Conditions"
                                    values={{what: 'react-intl'}}
                                />
                            </Link>
                        </li>
                        <li>
                            <Link to="/privacy" onClick={() => updateMenu()}>
                                <FormattedMessage id="app.footer.privacy"
                                    defaultMessage="Privacy Statement"
                                    description="Privacy Statement"
                                    values={{what: 'react-intl'}}
                                />
                            </Link>
                        </li>
                    </ul>
                    <div className='menu-license-mobile'>
                        <div>© 2022 by Beehire Personnel Limited.</div>
                        <div>EA License No.:57975</div>
                    </div>
                </div>
            </div>

            <JobsAlertLightbox
                isOpen={isOpen}
                canUpload={canUpload}
                selectedJob={selectedJob}
                selectedTags={selectedTags}
                toggleLightbox={toggleLightbox}
                resetSelectedTags={resetSelectedTags}
                instance={props.instance}
                isMobile={props.isMobile}
            />
        </>
    )
}

export default Navbar