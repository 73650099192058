import React, { useState, useEffect, useRef } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FormattedMessage } from 'react-intl';

import { emailCheck } from "../../utils/validation";
import '../../styles/Lightbox.css'
import './JobsAlertLightbox.css'
import circleTick from "../../images/circle_tick.png";

const JobsAlertLightbox = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [expertise, setExpertise] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [agree, setAgree] = useState(false);
    const [errors, setErrors] = useState([]);
    const [submmited, setSubmmited] = useState(false);
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        getExpertise();
    }, [props.selectedTags.length]);

    const getExpertise = () => {
        let temp = "";
        if (props.selectedTags.length > 0)
        {
            props.selectedTags.map((tag, i) => {
                temp += tag.tagName
                if (i + 1 !== props.selectedTags.length)
                {
                    return temp += ", "
                }
                else {
                    setExpertise(temp);
                    return expertise;
                }
            })
        }
        else
        {
            return expertise;
        }
        return expertise;
    }

    const closeLightbox = () => {
        setName("");
        setEmail("");
        setExpertise("");
        setSelectedFile(null);
        setAgree(false);
        setSubmmited(false);
        setErrors([]);
        props.resetSelectedTags();
        props.toggleLightbox();
    }

    const handleUploadClick = (event) => {
        hiddenFileInput.current.click();
    }

    const handleFileSelected = (event) => {
        if(event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    }

    const handleSubmit = () => {
        let errors = [];
        if (name.length === 0) {
            errors.push({ code: "E200", message: "Name cannot be empty."})
        }
        if (!emailCheck(email))
        {
            errors.push({ code: "E201", message: "Not a valid email address." })
        }

        if (expertise.length === 0) {
            errors.push({ code: "E202", message: "Please add atleast 1 expertise."})
        }

        if (!agree && props.canUpload) {
            errors.push({ code: "E203", message: "Please confirm the agreement of the usage of personal information for the purpose of sending you the job alerts."})
        }

        setErrors(errors);

        let customFields = [
            {
                field_id: 2,
                value: expertise
            }
        ]

        const params = {
            first_name: name,
            email: email,
            custom_fields: customFields
        }

        if (errors.length === 0) {
            try {
                props.instance.post(`/candidates`, params).then(response =>
                {
                    if(response.data.slug !== null)
                    {
                        if (props.canUpload)
                        {
                            const formData = new FormData();
                            formData.append("resume", selectedFile)
                            try {
                                props.instance.post(`/candidates/${response.data.slug}`, formData).then(res =>
                                {
                                    if (res)
                                    {
                                        console.log("upload success:")
                                        // closeLightbox();
                                        setSubmmited(true);
                                    }
                                })
                            }
                            catch (err)
                            {
                                console.error(err);
                            }
                        }
                        else
                        {
                            setSubmmited(true);
                        }
                    }
                })
            } catch (err) {
                console.error(err);
            }
        }
    }

    return(
        <div className={props.isOpen? props.isMobile? "lightbox-wrapper lightbox-wrapper-mobile show" : "lightbox-wrapper show" : "hide"}>
            {!submmited?
                <div className={props.canUpload? "lightbox" : "lightbox-viewmore"}>
                    <div className="lightbox-cross" onClick={()=> closeLightbox()}>
                        <AiOutlineCloseCircle/>
                    </div>
                        <div className={props.isMobile? "lightbox-title lightbox-title-mobile" : "lightbox-title"}>
                            <FormattedMessage id="app.jobs.lightbox.alert.title"
                                defaultMessage="Jobs Alert title"
                                description="Jobs Alert description"
                                values={{what: 'react-intl'}}/>
                        </div>

                        <div className="lightbox-label">
                            <FormattedMessage id={"app.jobs.lightbox.alert.fullname"}
                                defaultMessage="Full Name*"
                                description="Full Name*"
                                values={{what: 'react-intl'}}/>
                            <div className="mandatory">*</div>
                            {
                                errors.length> 0 ?
                                    errors.map(error => {
                                        if (error.code === "E200") {
                                            return (
                                                <div className="lightbox-error">{error.message}</div>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    })
                                : null
                            }
                        </div>

                        <div className="lightbox-input-wrapper">
                            <input className="lightbox-input" type="text" name="fullname" placeholder="e.g. Chan Tai Man" value={name} onChange={(e)=> setName(e.target.value)}/>
                        </div>

                        <div className="lightbox-label">
                            <FormattedMessage id={"app.jobs.lightbox.alert.email"}
                                defaultMessage="Contact Email*"
                                description="Contact Email*"
                                values={{what: 'react-intl'}}/>
                            <div className="mandatory">*</div>
                            {
                                errors.length> 0 ?
                                    errors.map(error => {
                                        if (error.code === "E201") {
                                            return (
                                                <div className="lightbox-error">{error.message}</div>
                                            )
                                        }
                                    })
                                : null
                            }
                        </div>

                        <div className="lightbox-input-wrapper">
                            <input className="lightbox-input" type="text" name="email" placeholder="e.g. tonychan@abc.com" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                        </div>

                        <div className="lightbox-label">
                            <FormattedMessage id={"app.jobs.lightbox.alert.expertise"}
                                defaultMessage="Contact Email*"
                                description="Contact Email*"
                                values={{what: 'react-intl'}}/>
                            <div className="mandatory">*</div>
                            {
                                errors.length> 0 ?
                                    errors.map(error => {
                                        if (error.code === "E202") {
                                            return (
                                                <div className="lightbox-error">{error.message}</div>
                                            )
                                        }
                                    })
                                : null
                            }
                        </div>

                        <div className="lightbox-input-wrapper">
                            <input className="lightbox-input" type="text" name="expertise" placeholder="e.g. iOS/Andriod Development, System Infrastucture" value={expertise} onChange={(e)=> setExpertise(e.target.value)}/>
                        </div>

                        {
                            props.canUpload?
                                <>
                                    <div className="lightbox-label">
                                        <FormattedMessage id={"app.jobs.lightbox.alert.submitCV"}
                                            defaultMessage="Contact Email*"
                                            description="Contact Email*"
                                            values={{what: 'react-intl'}}/>
                                        <div className="optional">
                                            <FormattedMessage id={"app.jobs.lightbox.alert.optional"}
                                            defaultMessage=" (Optional)"
                                            description=" (Optional)"
                                            values={{what: 'react-intl'}}/>
                                        </div>
                                    </div>
                                    <div className="lightbox-input-wrapper">
                                        <div className="lightbox-file-upload" onClick={handleUploadClick}>
                                            <FormattedMessage id={"app.jobs.lightbox.alert.uploadCV"}
                                                defaultMessage="Upload Your CV or Resume"
                                                description="Upload Your CV or Resume"
                                                values={{what: 'react-intl'}}/>
                                            <button type="button" className="lightbox-file-upload-button">
                                                <FormattedMessage id={"app.jobs.lightbox.alert.uploadCVBtn"}
                                                    defaultMessage="Upload File"
                                                    description="Upload File"
                                                    values={{what: 'react-intl'}}/>
                                            </button>
                                            {
                                                selectedFile?
                                                    <div className="lightbox-file-name">{selectedFile.name}</div>
                                                : null
                                            }
                                        </div>
                                        <input ref={hiddenFileInput} style={{display: 'none'}} type="file" onChange={handleFileSelected}/>
                                    </div>
                                    <div className="lightbox-input-wrapper">
                                        <div className="lightbox-checkbox-wrapper">
                                            <label className="lightbox-checkbox-label">
                                                <input type="checkbox" defaultChecked={agree} onChange={() => setAgree(!agree)}/>

                                                <FormattedMessage id={"app.jobs.lightbox.alert.agreement"}
                                                    defaultMessage="Agreement"
                                                    description="Agreement"
                                                    values={{what: 'react-intl'}}/>
                                            </label>
                                            {
                                                errors.length> 0 ?
                                                    errors.map(error => {
                                                        if (error.code === "E203") {
                                                            return (
                                                                <div className="lightbox-error lightbox-error-below">{error.message}</div>
                                                            )
                                                        }
                                                    })
                                                : null
                                            }
                                        </div>
                                    </div>
                                </>
                            : null
                        }

                        <button className="lightbox-alert-submit" type="button" onClick={(e) => handleSubmit(e)}>
                            <FormattedMessage id={"app.jobs.lightbox.alert.submit"}
                                defaultMessage="Submit"
                                description="Submit"
                                values={{what: 'react-intl'}}/>
                        </button>
                </div>
                :
                    <div className="lightbox-success">
                        <div className="lightbox-cross" onClick={()=> closeLightbox()}>
                            <AiOutlineCloseCircle/>
                        </div>
                        <img src={circleTick} alt="success" className="lightbox-success-tick"/>
                        <div className="lightbox-success-message">
                            <FormattedMessage id={"app.jobs.lightbox.success.submitted"}
                                defaultMessage="Your request have been submitted"
                                description="Your request have been submitted"
                                values={{what: 'react-intl'}}/>
                        </div>
                        <button className="lightbox-success-btn" onClick={()=> closeLightbox()}>
                            <FormattedMessage id={"app.jobs.lightbox.success.done"}
                                defaultMessage="Job Done!"
                                description="Job Done!"
                                values={{what: 'react-intl'}}/>
                        </button>
                    </div>
                }
            <div className="lightbox-background"></div>
        </div>
    )
}

export default JobsAlertLightbox