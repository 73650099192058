import React from "react"
import { FormattedMessage } from 'react-intl';
import dateFormat from 'dateformat';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

import './JobsSlider.css';

export const JobsSlider = (props)  => {

    var settings = {
        dots: false,
        infinite: props.sliderData.length > 1 ? true : false,
        speed: 500,
        slidesToShow: props.isMobile? 1 : 3,
        slidesToScroll: props.isMobile? 1 : 3,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: "linear",
        arrows: false
    };

    const splitValue = (value) => {
        if (value != null)
        {
            return value.split(',');
        }
        else
        {
            return value;
        }
    }

    return (
        <Slider {...settings}>
        {
            props.sliderData.map(data => {
                return (
                    <div key={`slider-block-${data.slug}`}>
                        <Link to={`/job_details/${data.slug}`} style={{textDecoration: "none"}}>
                            <div className={props.isMobile? 'slider-block slider-block-mobile' : 'slider-block'}>
                                <div className="slider-block-head">
                                    <div className='slider-block-title'>{data.name}</div>
                                    <div className="slider-block-city">{data.city === "" ? "Kowloon": data.city }</div>
                                    <div className="slider-block-exp">
                                        {data.minimum_experience}-{data.maximum_experience} <FormattedMessage id="app.slider.experience"
                                            defaultMessage="Slider"
                                            description="Slider"
                                            values={{what: 'react-intl'}}/>
                                    </div>
                                    <div className="slider-block-tags">
                                        {data.custom_fields.map( (field, index) => {
                                            return (
                                                index < 3 ?
                                                    field.value != null ?
                                                        splitValue(field.value).map((splitedValue, x) =>
                                                            <div className="slider-tag" key={`tag-${data.slug}-${x}`}>
                                                                {splitedValue}
                                                            </div>
                                                        )
                                                    : null
                                                : null
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="slider-block-footer">
                                    <div className="slider-block-date">
                                        <FormattedMessage id="app.slider.posted_by"
                                            defaultMessage="Post Date"
                                            description="Post Date"
                                            values={{what: 'react-intl'}}/>
                                        {dateFormat(data.updated_on, 'dd mmm yyyy')}
                                    </div>
                                    <div className="slider-block-salary">
                                        ~ ${data.max_annual_salary}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            })
        }
        </Slider>
    );
}