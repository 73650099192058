import React,{ useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Quote from '../images/quote.png';
import { ImageTextBlock } from '../components/ImageTextBlock/ImageTextBlock';
import HeaderLogo from '../images/about-header-logo.png';
import HeaderLogo_m from '../images/mobile/about-header-logo.png';
import AboutContent3Logo from '../images/about-content-2-logo.png';
import AboutContent3Logo_m from '../images/mobile/about-content-2-logo.png';
import Bubble from '../images/bubble.png';
import Bubble_m from '../images/mobile/bubble.png';
import Caesar from '../images/staffs/caesar.png';
import Michelle from '../images/staffs/michelle.png';
import HeaderBg from '../images/header_bg.png';
import HeaderBg_m from '../images/mobile/header_bg.png';

export const About = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
		if (props.isScroll)
		{
			executeScroll();
			props.setNeedScroll(false);
		}
        props.setLink("AboutUs");
    }, []);

    const [staffs, setStaffs] = useState([
        {
            photo: Caesar,
            name: "Caesar Hui",
            title: "Consulting Manager",
            area: "Specialized Area",
            link: "https://www.linkedin.com/in/caesar-hui-b19002146/"
        },
        {
            photo: Michelle,
            name: "Michelle Wu",
            title: "Consulting Manager",
            area: "Specialized Area",
            link: "https://www.linkedin.com/in/michelle-wu-44511a12b/"
        },
        {
            photo: '',
            name: "",
            title: "",
            area: "",
            link: ""
        },
        {
            photo: '',
            name: "",
            title: "",
            area: "",
            link: ""
        },
    ])

	const scroller = useRef(null);
    const executeScroll = () => scroller.current.scrollIntoView({behavior: 'smooth'});

    const redirectToLinkIn = (link) => {
        if (link !== "")
        {
            window.open(
                link,
                '_blank'
            );
        }
    }

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            {props.isMobile?
                <>
                    <div className='about-header-background about-header-background-mobile'>
                        <img src={HeaderBg_m} className="about-header-bg-mobile" alt="about-header-background"/>
                        <img className="about-header-logo-mobile" src={HeaderLogo_m} alt="header-bg"/>
                    </div>
                    <div className="content-wrapper-mobile">
                        <div className='about-us-content-1 about-us-content-1-mobile'>
                            <div className="breadcrumb">
                                <FormattedMessage id="app.breadcrumb.home"
                                    defaultMessage="Home page"
                                    description="Home page"
                                    values={{what: 'react-intl'}}
                                />
                                \ <FormattedMessage id="app.breadcrumb.about"
                                    defaultMessage="About Us"
                                    description="About Us"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.about_us.content_1.title"
                                    defaultMessage="About us"
                                    description="About us"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div className='about-us-content-1-content about-us-content-1-content-mobile'>
                                        <div>
                                            <img src={Quote} alt='quote' />
                                        </div>
                                        <div className="about-us-content-1-text-mobile">
                                            <FormattedMessage id="app.about_us.content_1.content"
                                                defaultMessage="About us"
                                                description="About us"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </div>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className='about-content-2-logo about-content-2-logo-mobile'>
                        <img src={AboutContent3Logo_m} alt="content-2-logo"/>
                    </div>

                    <div className='content-wrapper-mobile about-us-content-2-wrapper about-us-content-2-wrapper-mobile'>
                        <div>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.about_us.content_2.title_m"
                                    defaultMessage="About Us"
                                    description="content 2 Title"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.about_us.content_2.content"
                                            defaultMessage="About Us"
                                            description="content 2 Content"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}

                                        />
                                        <div className="about-us-content-2-footer about-us-content-2-footer-mobile">
                                            <div>
                                                <FormattedMessage id="app.about_us.content_2.footer"
                                                    defaultMessage="About Us"
                                                    description="About Us content 2 Footer"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </div>
                                            <div>
                                                <Link to="/join_us" className='about-us-content-2-link'>
                                                    <FormattedMessage id="app.about_us.content_2.link"
                                                        defaultMessage="About Us"
                                                        description="About Us content 2 Footer"
                                                        values={{what: 'react-intl'}}
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className='about-bubble about-bubble-mobile'>
                        <img className="about-bubble-image" src={Bubble_m} alt="content-3-bubble"/>
                    </div>
                    <div className='content-wrapper-mobile about-us-content-3 about-us-content-3-mobile'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.about_us.content_3.title"
                                    defaultMessage="About Us Page"
                                    description="Block 3 Title"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.about_us.content_3.content_m"
                                            defaultMessage="About Us Page"
                                            description="Block 3 Content"
                                            values={{what: 'react-intl'}}

                                        />
                                    </div>
                                }
                                reverse
                                textColor={'#FFF'}
                            />
                    </div>

                    <div className='content-wrapper-mobile about-us-content-4-wrapper'>
                        <div className='about-us-content-4-title about-us-content-4-title-mobile' ref={scroller}>
                            <FormattedMessage id="app.about_us.content_4.title"
                                defaultMessage="About Us Page"
                                description="Block 4 Title"
                                values={{what: 'react-intl'}}
                            />
                        </div>

                        <div className='team-list-wrapper team-list-wrapper-mobile'>
                            {
                                staffs.map((staff, index) => {
                                    return (
                                        <div className='team-list-block' key={`staff-${index}`}>
                                            {staff.photo.length > 0 ?
                                                <img className='team-list-photo' src={staff.photo} alt={staff.name}/>
                                                :
                                                <div className='team-list-photo-default'></div>
                                            }
                                            <div className='team-list-name'>
                                                {staff.name}
                                            </div>
                                            <div className='team-list-title'>
                                                {staff.title}
                                            </div>
                                            <div className='team-list-area'>
                                                {staff.area}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='team-list-button-wrapper'>
                            <Link className='team-list-button' to={'/join_us'} onClick={()=> props.setNeedScroll(true)}>
                                <FormattedMessage id="app.about_us.content_4.button"
                                    defaultMessage="About Us Page"
                                    description="Block 4 Button"
                                    values={{what: 'react-intl'}}
                                />
                            </Link>
                        </div>
                    </div>
                </>
            :
                <>
                    <div className='about-header-background'>
                        <img src={HeaderBg} className="about-header-bg" alt="about-header-background"/>
                        <img className="about-header-logo" src={HeaderLogo} alt="header-bg"/>
                    </div>
                    <div className="content-wrapper">
                        <div className='about-us-content-1'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.about_us.content_1.title"
                                    defaultMessage="About us"
                                    description="About us"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div className='about-us-content-1-content'>
                                        <div>
                                            <img src={Quote} alt='quote' />
                                        </div>
                                        <div>
                                            <FormattedMessage id="app.about_us.content_1.content"
                                                defaultMessage="About us"
                                                description="About us"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </div>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className='about-content-2-logo'>
                        <img src={AboutContent3Logo} alt="content-2-logo"/>
                    </div>

                    <div className='content-wrapper about-us-content-2-wrapper'>
                        <div>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.about_us.content_2.title"
                                    defaultMessage="About Us"
                                    description="content 2 Title"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.about_us.content_2.content"
                                            defaultMessage="About Us"
                                            description="content 2 Content"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}

                                        />
                                        <div className="about-us-content-2-footer">
                                            <div>
                                                <FormattedMessage id="app.about_us.content_2.footer"
                                                    defaultMessage="About Us"
                                                    description="About Us content 2 Footer"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </div>
                                            <Link to="/join_us" className='about-us-content-2-link'>
                                                <FormattedMessage id="app.about_us.content_2.link"
                                                    defaultMessage="About Us"
                                                    description="About Us content 2 Footer"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className='about-bubble'>
                        <img className="about-bubble-image" src={Bubble} alt="content-3-bubble"/>
                    </div>
                    <div className='content-wrapper about-us-content-3'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.about_us.content_3.title"
                                    defaultMessage="About Us Page"
                                    description="Block 3 Title"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.about_us.content_3.content"
                                            defaultMessage="About Us Page"
                                            description="Block 3 Content"
                                            values={{what: 'react-intl'}}

                                        />
                                    </div>
                                }
                                reverse
                                textColor={'#FFF'}
                            />
                    </div>

                    <div className='content-wrapper about-us-content-4-wrapper'>
                        <div className='about-us-content-4-title' ref={scroller}>
                            <FormattedMessage id="app.about_us.content_4.title"
                                defaultMessage="About Us Page"
                                description="Block 4 Title"
                                values={{what: 'react-intl'}}
                            />
                        </div>

                        <div className='team-list-wrapper'>
                            {
                                staffs.map((staff, index) => {
                                    return (
                                        <div className='team-list-block' key={`staff-${index}`} onClick={() => redirectToLinkIn(staff.link)}>
                                            {staff.photo.length > 0 ?
                                                <img className='team-list-photo' src={staff.photo} alt={staff.name}/>
                                                :
                                                <div className='team-list-photo-default'></div>
                                            }
                                            <div className='team-list-name'>
                                                {staff.name}
                                            </div>
                                            <div className='team-list-title'>
                                                {staff.title}
                                            </div>
                                            <div className='team-list-area'>
                                                {staff.area}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='team-list-button-wrapper'>
                            <Link className='team-list-button' to={'/join_us'} onClick={()=> props.setNeedScroll(true)}>
                                <FormattedMessage id="app.about_us.content_4.button"
                                    defaultMessage="About Us Page"
                                    description="Block 4 Button"
                                    values={{what: 'react-intl'}}
                                />
                            </Link>
                        </div>
                    </div>
                </>
            }
        </main>
    );
}