import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AiOutlineCloseCircle } from "react-icons/ai";

import LogoCheckBox from '../images/logo_checkbox.png';
import { ImageTextBlock } from '../components/ImageTextBlock/ImageTextBlock';
import ContactForm from '../components/ContactForm/ContactForm';

import HeaderBg from '../images/header_bg.png';
import HeaderBg_m from '../images/mobile/header_bg.png';
import HeaderLogo from '../images/talnet-header-logo.png';
import HeaderLogo_m from '../images/mobile/talnet-header-logo.png';
import TalnetContent3Logo from '../images/talnet-content-3-logo.png';
import TalnetContent3Logo_m from '../images/mobile/talnet-content-3-logo.png';
import Bubble from '../images/bubble.png';
import Bubble_m from '../images/mobile/bubble.png';

export const Talent = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        props.setLink("Talent");
    }, []);

    // const [trends, setTrends] = useState([
    //     { trendId: 1, thumbnail: LogoCheckBox, title: "HR Trend Article Title how to hire good talent that match your company ", reporter: "Trend Report" },
    //     { trendId: 2, thumbnail: LogoCheckBox, title: "HR Trend Article Title how to hire good talent that match your company ", reporter: "Trend Report" },
    //     { trendId: 3, thumbnail: LogoCheckBox, title: "HR Trend Article Title how to hire good talent that match your company ", reporter: "Trend Report" },
    //     { trendId: 4, thumbnail: LogoCheckBox, title: "HR Trend Article Title how to hire good talent that match your company ", reporter: "Trend Report" },
    //     { trendId: 5, thumbnail: LogoCheckBox, title: "HR Trend Article Title how to hire good talent that match your company ", reporter: "Trend Report" },
    //     { trendId: 6, thumbnail: LogoCheckBox, title: "HR Trend Article Title how to hire good talent that match your company ", reporter: "Trend Report" },
    // ]);

    const openForm = () => {
        document.getElementById("overlay").style.display = "block";
        document.getElementById("popup").style.display = "block";
        document.body.classList.add("noscroll");
    }

    const closeForm = () => {
        document.getElementById("overlay").style.display = "none";
        document.getElementById("popup").style.display = "none";
        document.body.className = document.body.className.replace("noscroll","");
    }

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            {props.isMobile?
                <>
                    <div className='header-background talnet-header-background-mobile'>
                        <img src={HeaderBg_m} className="talnet-header-bg-mobile" alt="talnet-header-background"/>
                        <img src={HeaderLogo_m} className="talnet-header-logo-mobile" alt="talnet-header-background"/>
                    </div>
                    <div className="content-wrapper-mobile">
                        <div className='talnet-content-1 talnet-content-1-mobile'>
                            <div className="breadcrumb">
                                <FormattedMessage id="app.breadcrumb.home"
                                    defaultMessage="Home page"
                                    description="Home page"
                                    values={{what: 'react-intl'}}
                                />
                                \ <FormattedMessage id="app.breadcrumb.talnet"
                                    defaultMessage="Talnet Seeking"
                                    description="Talnet Seeking"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <ImageTextBlock
                                title={
                                    <FormattedMessage id="app.talnet.content_1.title_m"
                                        defaultMessage="Talnet Seeking"
                                        description="Talnet Seeking"
                                        values={{what: 'react-intl'}}
                                    />
                                }
                                content={
                                    <div>
                                        <FormattedMessage id="app.talnet.content_1.content_m"
                                            defaultMessage="Talnet Seeking"
                                            description="Talnet Seeking"
                                            values={{what: 'react-intl'}}

                                        />
                                        <div className="talnet-btn">
                                            {/* <Button
                                                ButtonTextId="app.talnet.content_1.button"
                                                DefaultText="Looking for talnet"
                                                ButtonDesc="Looking for talnet"
                                                ButtonLink="/talent"/> */}
                                            <div className="button default">
                                                {/* <a className="button-link default" href="https://alt.jotfor.ms/230763925922461" target="popup">
                                                    <FormattedMessage id="app.talnet.content_1.button"
                                                        defaultMessage="Looking for talnet"
                                                        description="Looking for talnet"
                                                        values={{what: 'react-intl'}}/>
                                                </a> */}
                                                <a className="button-link default" onClick={() => openForm()}>
                                                    <FormattedMessage id="app.talnet.content_1.button"
                                                        defaultMessage="Looking for talnet"
                                                        description="Looking for talnet"
                                                        values={{what: 'react-intl'}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className='talnet-bubble-mobile'>
                        <img src={Bubble_m} />
                    </div>
                    <div className='content-wrapper-mobile talnet-content-2-mobile'>
                        <ImageTextBlock
                            title={<FormattedMessage id="app.talnet.content_2.title"
                                defaultMessage="Talnet Page"
                                description="Block 2 Title"
                                values={{what: 'react-intl'}}
                            />}
                            content={
                                <div>
                                    <FormattedMessage id="app.talnet.content_2.content_m"
                                        defaultMessage="Talnet Page"
                                        description="Block 2 Content"
                                        values={{what: 'react-intl'}}

                                    />
                                    <FormattedMessage id="app.talnet.content_2.subcontent"
                                        defaultMessage="Talnet Page"
                                        description="talnet 2 content"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                            }
                            reverse
                            textColor={'#FFF'}
                            contentWidth="100%"
                        />
                        <div className="talnet-btn-wrapper talnet-btn-wrapper-mobile">
                            <div className="button primary">
                                <Link className="button-link primary" to="/about_us" onClick={()=> props.setNeedScroll(true)}>
                                    <FormattedMessage id="app.talnet.content_2.button"
                                        defaultMessage="Meet our team"
                                        description="Meet our team"
                                        values={{what: 'react-intl'}}/>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='talnet-content-3-logo-mobile'>
                        <img src={TalnetContent3Logo_m} />
                    </div>
                    <div className='content-wrapper-mobile '>
                        <div className='talnet-content-3 talnet-content-3-mobile'>
                            <div className='talnet-content-3-title-mobile'>
                                <FormattedMessage id="app.talnet.content_3.title"
                                    defaultMessage="Talnet Page"
                                    description="talnet 3 title"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='talnet-content-3-content-mobile'>
                                <FormattedMessage id="app.talnet.content_3.content_m"
                                    defaultMessage="Talnet Page"
                                    description="talnet 3 content"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className='talnet-content-3-cards-wrapper talnet-content-3-cards-wrapper-mobile'>
                                <div className='talnet-content-3-card talnet-content-3-card-mobile'>
                                    <div className='talnet-content-3-card-title-wrapper'>
                                        <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                        <div className='talnet-content-3-card-title'>
                                            <FormattedMessage id="app.talnet.content_3.card_1.title"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_1 title"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>

                                    <div className='talnet-content-3-card-content'>
                                        <FormattedMessage id="app.talnet.content_3.card_1.content"
                                            defaultMessage="Talnet Page"
                                            description="Block 3 card_1 content"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>

                                <div className='talnet-content-3-card talnet-content-3-card-mobile talnet-content-3-card-3-mobile'>
                                    <div className='talnet-content-3-card-title-wrapper'>
                                        <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                        <div className='talnet-content-3-card-title'>
                                            <FormattedMessage id="app.talnet.content_3.card_2.title"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_2 title"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>

                                    <div className='talnet-content-3-card-content'>
                                        <FormattedMessage id="app.talnet.content_3.card_2.content"
                                            defaultMessage="Talnet Page"
                                            description="Block 3 card_2 content"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>

                                <div className='talnet-content-3-card talnet-content-3-card-3-mobile'>
                                    <div className='talnet-content-3-card-title-wrapper'>
                                        <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                        <div className='talnet-content-3-card-title'>
                                            <FormattedMessage id="app.talnet.content_3.card_3.title"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_3 title"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>

                                    <div className='talnet-content-3-card-content'>
                                        <FormattedMessage id="app.talnet.content_3.card_3.content"
                                            defaultMessage="Talnet Page"
                                            description="Block 3 card_3 content"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>

                                <div className='talnet-content-3-card talnet-content-3-card-mobile'>
                                    <div className='talnet-content-3-card-title-wrapper'>
                                        <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                        <div className='talnet-content-3-card-title'>
                                            <FormattedMessage id="app.talnet.content_3.card_4.title"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_4 title"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>

                                    <div className='talnet-content-3-card-content' style={{marginTop: "20px"}}>
                                        <FormattedMessage id="app.talnet.content_3.card_4.content"
                                            defaultMessage="Talnet Page"
                                            description="Block 3 card_4 content"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="overlay" onClick={() => closeForm()}></div>
                        <div id="popup" className='lightbox popup popup-mobile'>
                            <div className="lightbox-cross" onClick={()=> closeForm()}>
                                <AiOutlineCloseCircle/>
                            </div>
                            <ContactForm closeForm={() => closeForm()} isMobile={props.isMobile}/>
                        </div>
                    </div>
                </>
            :
                <>
                    <div className='talnet-header-background'>
                        <img src={HeaderBg} className="talnet-header-bg" alt="talnet-header-background"/>
                        <img className="talnet-header-logo" src={HeaderLogo} />
                    </div>
                    <div className="content-wrapper">
                        <div className='talnet-content-1'>
                            <ImageTextBlock
                                contentTitle={<FormattedMessage id="app.talnet.content_1.title"
                                    defaultMessage="Talnet Seeking"
                                    description="Talnet Seeking"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.talnet.content_1.content"
                                            defaultMessage="Talnet Seeking"
                                            description="Talnet Seeking"
                                            values={{what: 'react-intl'}}

                                        />
                                        <div className="talnet-btn">
                                            <div className="button default">
                                                <a className="button-link default" onClick={() => openForm()}>
                                                    <FormattedMessage id="app.talnet.content_1.button"
                                                        defaultMessage="Looking for talnet"
                                                        description="Looking for talnet"
                                                        values={{what: 'react-intl'}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                contentWidth="80%"
                                reverse
                            />
                        </div>
                    </div>

                    <div className='talnet-bubble'>
                        <img src={Bubble} />
                    </div>
                    <div className='content-wrapper talnet-content-2'>
                        <ImageTextBlock
                            title={<FormattedMessage id="app.talnet.content_2.title"
                                defaultMessage="Talnet Page"
                                description="Block 2 Title"
                                values={{what: 'react-intl'}}
                            />}
                            content={
                                <div>
                                    <FormattedMessage id="app.talnet.content_2.content"
                                        defaultMessage="Talnet Page"
                                        description="Block 2 Content"
                                        values={{what: 'react-intl'}}

                                    />
                                    <FormattedMessage id="app.talnet.content_2.subcontent"
                                        defaultMessage="Talnet Page"
                                        description="talnet 2 content"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                            }
                            reverse
                            textColor={'#FFF'}
                            contentWidth="100%"
                        />
                        <div className="talnet-btn-wrapper">
                            <div className="button primary">
                                <Link className="button-link primary" to="/about_us" onClick={()=> props.setNeedScroll(true)}>
                                    <FormattedMessage id="app.talnet.content_2.button"
                                        defaultMessage="Meet our team"
                                        description="Meet our team"
                                        values={{what: 'react-intl'}}/>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='talnet-content-3-logo'>
                        <img src={TalnetContent3Logo} />
                    </div>
                    <div className='content-wrapper talnet-content-3-wrapper'>
                        <div className='talnet-content-3'>
                                <div className='talnet-content-3-title'>
                                    <FormattedMessage id="app.talnet.content_3.title"
                                        defaultMessage="Talnet Page"
                                        description="talnet 3 title"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='talnet-content-3-content'>
                                    <FormattedMessage id="app.talnet.content_3.content"
                                        defaultMessage="Talnet Page"
                                        description="talnet 3 content"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>

                                <div className='talnet-content-3-cards-wrapper'>
                                    <div className='talnet-content-3-card'>
                                        <div className='talnet-content-3-card-title-wrapper'>
                                            <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                            <div className='talnet-content-3-card-title'>
                                                <FormattedMessage id="app.talnet.content_3.card_1.title"
                                                    defaultMessage="Talnet Page"
                                                    description="Block 3 card_1 title"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </div>
                                        </div>

                                        <div className='talnet-content-3-card-content'>
                                            <FormattedMessage id="app.talnet.content_3.card_1.content"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_1 content"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>

                                    <div className='talnet-content-3-card'>
                                        <div className='talnet-content-3-card-title-wrapper'>
                                            <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                            <div className='talnet-content-3-card-title'>
                                                <FormattedMessage id="app.talnet.content_3.card_2.title"
                                                    defaultMessage="Talnet Page"
                                                    description="Block 3 card_2 title"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </div>
                                        </div>

                                        <div className='talnet-content-3-card-content'>
                                            <FormattedMessage id="app.talnet.content_3.card_2.content"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_2 content"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>

                                    <div className='talnet-content-3-card'>
                                        <div className='talnet-content-3-card-title-wrapper'>
                                            <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                            <div className='talnet-content-3-card-title'>
                                                <FormattedMessage id="app.talnet.content_3.card_3.title"
                                                    defaultMessage="Talnet Page"
                                                    description="Block 3 card_3 title"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </div>
                                        </div>

                                        <div className='talnet-content-3-card-content'>
                                            <FormattedMessage id="app.talnet.content_3.card_3.content"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_3 content"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>

                                    <div className='talnet-content-3-card'>
                                        <div className='talnet-content-3-card-title-wrapper'>
                                            <img src={LogoCheckBox} alt='check' className='talnet-content-3-card-logo-checkbox'/>
                                            <div className='talnet-content-3-card-title'>
                                                <FormattedMessage id="app.talnet.content_3.card_4.title"
                                                    defaultMessage="Talnet Page"
                                                    description="Block 3 card_4 title"
                                                    values={{what: 'react-intl'}}
                                                />
                                            </div>
                                        </div>

                                        <div className='talnet-content-3-card-content'>
                                            <FormattedMessage id="app.talnet.content_3.card_4.content"
                                                defaultMessage="Talnet Page"
                                                description="Block 3 card_4 content"
                                                values={{what: 'react-intl'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div id="overlay" onClick={() => closeForm()}></div>
                    <div id="popup" className='lightbox popup'>
                        <div className="lightbox-cross" onClick={()=> closeForm()}>
                            <AiOutlineCloseCircle/>
                        </div>
                        <ContactForm closeForm={() => closeForm()} isMobile={props.isMobile}/>
                    </div>

                    {/* <div className='content-wrapper'>
                        <div className='talnet-content-4'>
                            <div className='talnet-content-4-title'>
                                <FormattedMessage id="app.talnet.content_4.title"
                                        defaultMessage="Talnet Page"
                                        description="Talnet content 4 title"
                                        values={{what: 'react-intl'}}
                                />

                                <div className='talnet-content-4-trends-wrapper'>
                                    {
                                        trends.map(trend => {
                                            return (
                                                <div className="trend-block">
                                                    <img src={trend.thumbnail} alt={`trend-${trend.id}`} className='trend-block-thumbnail'/>
                                                    <div className='trend-block-title'>{trend.title}</div>
                                                    <div className='trend-reporter'>{trend.reporter}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className='talnet-content-link-footer talnet-content-4-footer'>
                                    <Link  to="/jobs">
                                            <FormattedMessage id="app.talnet.more_article"
                                                defaultMessage="View More Article"
                                                description="View More Article"
                                                values={{what: 'react-intl'}}
                                            />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </>
            }
        </main>
    );
}