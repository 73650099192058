import React, { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import HeaderBg from '../images/header_bg.png';
import HeaderBg_m from '../images/mobile/header_bg.png';

export const Terms = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        props.setLink("Terms");
    }, []);

    const SourcesReference = (source) => {
        return  (
                    <div>
                        <a href={source.link} target="_blank" style={{wordWrap: "break-word", textDecoration: "none", color: "RGB(5, 99, 193)"}}>{source.name}</a>
                        <br/>
                        <br/>
                    </div>
                )
    }

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            {props.isMobile?
                <>
                    <div className='terms-header-background terms-header-background-mobile'>
                        <img src={HeaderBg_m} className="terms-header-bg-mobile" alt="terms-header-background"/>
                    </div>

                    <div className="content-wrapper-mobile terms-content-wrapper-mobile">
                        <div className="breadcrumb">
                            <FormattedMessage id="app.breadcrumb.home"
                                defaultMessage="Home page"
                                description="Home page"
                                values={{what: 'react-intl'}}
                            />
                            \ <FormattedMessage id="app.breadcrumb.terms"
                                defaultMessage="Terms"
                                description="Terms"
                                values={{what: 'react-intl'}}
                            />
                        </div>
                        <div className='terms-title-mobile'>
                            <FormattedMessage id="app.terrms.title"
                                defaultMessage="Terms & Conditions"
                                description="Terms & Conditions"
                                values={{what: 'react-intl'}}
                            />
                        </div>

                        <div className='content-wrapper-mobile terms-content terms-content-mobile'>
                            <FormattedMessage id="app.terms.content"
                                defaultMessage="Terms"
                                description="Terms"
                                values={{what: 'react-intl'}}
                            />


                        <a href="mailto:info@beehire.co">info@beehire.co</a>

                        <FormattedMessage id="app.terms.content_2"
                            defaultMessage="Terms"
                            description="Terms"
                            values={{what: 'react-intl'}}
                        />
                        {SourcesReference({name: "Business people photograph designed by Freepik.com", link: "https://www.freepik.com/free-photo/business-people-office-break_12162228.htm"})}
                        {SourcesReference({name: "Sewing items image designed by sergiorojoes - Freepik.com", link: "https://www.freepik.com/free-photo/sewing-items-arranged-neatly_14575979.htm#query=button&position=23&from_view=search"})}
                        {SourcesReference({name: "Garden plants photo created by visnezh - Freepik.com", link: "https://www.freepik.com/photos/garden-plants"})}
                        {SourcesReference({name: "Honeycomb Image by jcomp - Freepik.com", link: "https://www.freepik.com/photos/garden-plants"})}
                        {SourcesReference({name: "Sphere vector created by GarryKillian - Freepik.com", link: "https://www.freepik.com/vectors/sphere"})}
                        {SourcesReference({name: "3d icon psd created by jordy_pp - Freepik.com", link: "https://www.freepik.com/psd/3d-icon"})}
                        {SourcesReference({name: "Development team photo created by pressfoto - Freepik.com", link: "https://www.freepik.com/photos/development-team"})}
                        {SourcesReference({name: "Bee world photo by David Clodeon Unsplash", link: "https://unsplash.com/photos/wRt8myE0W00"})}
                        </div>
                    </div>
                </>
            :
                <>
                    <div className='terms-header-background'>
                        <img src={HeaderBg} className="terms-header-bg" alt="terms-header-background"/>
                    </div>
                    <div className="content-wrapper">
                        <div className='terms-title'>
                            <FormattedMessage id="app.terrms.title"
                                defaultMessage="Terms & Conditions"
                                description="Terms & Conditions"
                                values={{what: 'react-intl'}}
                            />
                        </div>
                    </div>
                    <div className='content-wrapper terms-content'>
                        <FormattedMessage id="app.terms.content"
                            defaultMessage="Terms"
                            description="Terms"
                            values={{what: 'react-intl'}}
                        />
                        <a href="mailto:info@beehire.co">info@beehire.co</a>

                        <FormattedMessage id="app.terms.content_2"
                            defaultMessage="Terms"
                            description="Terms"
                            values={{what: 'react-intl'}}
                        />
                        {SourcesReference({name: "Business people photograph designed by Freepik.com", link: "https://www.freepik.com/free-photo/business-people-office-break_12162228.htm"})}
                        {SourcesReference({name: "Sewing items image designed by sergiorojoes - Freepik.com", link: "https://www.freepik.com/free-photo/sewing-items-arranged-neatly_14575979.htm#query=button&position=23&from_view=search"})}
                        {SourcesReference({name: "Garden plants photo created by visnezh - Freepik.com", link: "https://www.freepik.com/photos/garden-plants"})}
                        {SourcesReference({name: "Honeycomb Image by jcomp - Freepik.com", link: "https://www.freepik.com/photos/garden-plants"})}
                        {SourcesReference({name: "Sphere vector created by GarryKillian - Freepik.com", link: "https://www.freepik.com/vectors/sphere"})}
                        {SourcesReference({name: "3d icon psd created by jordy_pp - Freepik.com", link: "https://www.freepik.com/psd/3d-icon"})}
                        {SourcesReference({name: "Development team photo created by pressfoto - Freepik.com", link: "https://www.freepik.com/photos/development-team"})}
                        {SourcesReference({name: "Bee world photo by David Clodeon Unsplash", link: "https://unsplash.com/photos/wRt8myE0W00"})}
                    </div>
                </>
            }
        </main>
    );
}