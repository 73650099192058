import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ImageTextBlock } from '../components/ImageTextBlock/ImageTextBlock';
import ContactForm from '../components/ContactForm/ContactForm';

import HeaderLogo from '../images/location-header-logo.png';
import HeaderLogo_m from '../images/mobile/location-header-logo.png';

import HeaderBg_m from '../images/mobile/header_bg.png';
import HeaderBg from '../images/header_bg.png';

import LocationBannerBg1 from '../images/location-banner-bg-1.png';
import LocationBannerBg2 from '../images/location-banner-bg-2.png';
import LocationBannerBg3 from '../images/location-banner-bg-3.png';

import LocationBannerBg1_m from '../images/mobile/location-banner-bg-1.png';
import LocationBannerBg2_m from '../images/mobile/location-banner-bg-2.png';
import LocationBannerBg3_m from '../images/mobile/location-banner-bg-3.png';

export const Locations = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        props.setLink("Locations");
    }, []);

    const images_hk = [
		"https://assets.iwgplc.com/image/upload/f_auto,q_auto,w_834,h_522,c_fill/CentreImagery/5014/5014_1.jpg",
		"https://assets.iwgplc.com/image/upload/f_auto,q_auto,w_834,h_522,c_fill/CentreImagery/5014/5014_2.jpg",
	];

    const images_tw = [
        "https://coworker.imgix.net/photos/taiwan/taipei/spaces-taipei-spaces-hung-sheng-ifc/main-1611661773.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle",
        "https://coworker.imgix.net/photos/taiwan/taipei/spaces-taipei-spaces-hung-sheng-ifc/6a03d2c527fae3880427c2abf89105c5.jpg?w=800&h=0&q=90&auto=format,compress&fit=crop&mark=/template/img/wm_icon.png&markscale=5&markalign=center,middle"
    ];

    const images_jp = [];

    const addressIframeHK = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.010654235833!2d114.17233141532735!3d22.277586249347657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404005b9d751aa7%3A0x27c09853391a49d0!2z54Gj5LuU6LuS5bC86Kmp6YGTMjAw6Jmf5oGG55Sf54Gj5LuU5aSn5buI!5e0!3m2!1szh-TW!2shk!4v1679278855687!5m2!1szh-TW!2shk";
    const addressLinkHK = "https://goo.gl/maps/TZU9mN78JxBB4LSD6";

    const addressIframeTW = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.4803602974935!2d121.53959811536099!3d25.05170304373477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abba16666b31%3A0x821d67ed52f86459!2zU3BhY2Vz5Y-w5YyX5a6P55ubSUZD!5e0!3m2!1szh-TW!2shk!4v1679278903200!5m2!1szh-TW!2shk";
    const addressLinkTW = "https://goo.gl/maps/37nMH4ZxkwaAvcci6";

    const addressIframeJP = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.587450072023!2d139.72373633088554!3d35.66253467528078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b79c1e9a9cf%3A0xbb374b8588b37408!2sRoppongi%20Seven%2C%207-ch%C5%8Dme-18-1%20Roppongi%2C%20Minato%20City%2C%20Tokyo%20106-0032%E6%97%A5%E6%9C%AC!5e0!3m2!1szh-TW!2shk!4v1679278961159!5m2!1szh-TW!2shk";
    const addressLinkJP = "https://goo.gl/maps/kYg9YkYmnNpSgZ7w5";

    const openForm = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        document.getElementById("overlay").style.display = "block";
        document.getElementById("popup").style.display = "block";
        document.body.classList.add("noscroll");
    }

    const closeForm = () => {
        document.getElementById("overlay").style.display = "none";
        document.getElementById("popup").style.display = "none";
        document.body.className = document.body.className.replace("noscroll","");
    }

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            {props.isMobile?
                <>
                    <div className='location-header-background location-header-background-mobile'>
                        <img src={HeaderBg_m} className="location-header-bg-mobile" alt="location-header-background"/>
						<img className="location-header-logo location-header-logo-mobile" src={HeaderLogo_m} />
					</div>
                    <div className="content-wrapper-mobile">
                        <div className='location-content-1 location-content-1-mobile'>
                            <div className="breadcrumb">
                                <FormattedMessage id="app.breadcrumb.home"
                                    defaultMessage="Home page"
                                    description="Home page"
                                    values={{what: 'react-intl'}}
                                />
                                \ <FormattedMessage id="app.breadcrumb.location"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.locations.content_1.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_1.content_m"
                                            defaultMessage="About us"
                                            description="About us"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className="content-wrapper-mobile location-content-2-wrapper">
                        <div className='location-content-2 location-content-2-mobile'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.locations.content_2.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_2.content_m"
                                            defaultMessage="Location"
                                            description="Location"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                        <a className="location-content-2-email" href="mailto:info@beehire.co">info@beehire.co</a>

                                        <div className='location-content-2-photos location-content-2-photos-mobile'>
                                            {
                                                images_hk.map((image, index) => {
                                                    return (
                                                        <div className='location-content-2-photo-wrapper location-content-2-photo-wrapper-mobile' key={`location-img-${index}`}>
                                                            <img className='location-content-2-photo location-content-2-photo-mobile' src={image} alt={image} key={image}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                overwriteImageBlock={
                                    <div className='location-content-2-map location-content-2-map-mobile'>
                                        <iframe src={addressIframeHK} width="320" height="280" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                                        <a className="location-content-2-address" target="_blank" href={addressLinkHK}>
                                            <FormattedMessage id="app.locations.content_2.map_view"
                                                defaultMessage="Location"
                                                description="Location"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </a>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className="content-wrapper-mobile location-content-2-wrapper">
                        <div className='location-content-2 location-content-zero-top'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.locations.content_3.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_3.content_m"
                                            defaultMessage="Location"
                                            description="Location"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                        <a className="location-content-2-email" href="mailto:info@beehire.co">info@beehire.co</a>

                                        <div className='location-content-2-photos location-content-2-photos-mobile'>
                                            {
                                                images_tw.map((image, index) => {
                                                    return (
                                                        <div className='location-content-2-photo-wrapper location-content-2-photo-wrapper-mobile' key={`location-img-${index}`}>
                                                            <img className='location-content-2-photo location-content-2-photo-mobile' src={image} alt={image} key={image}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                overwriteImageBlock={
                                    <div className='location-content-2-map location-content-2-map-mobile'>
                                        <iframe src={addressIframeTW} width="320" height="280" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                                        <a className="location-content-2-address" target="_blank" href={addressLinkTW}>
                                            <FormattedMessage id="app.locations.content_2.map_view"
                                                defaultMessage="Location"
                                                description="Location"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </a>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className="content-wrapper-mobile location-content-2-wrapper">
                        <div className='location-content-2 location-content-zero-top'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.locations.content_5.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_5.content_m"
                                            defaultMessage="Location"
                                            description="Location"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                        <a className="location-content-2-email" href="mailto:info@beehire.co">info@beehire.co</a>

                                        <div className='location-content-2-photos location-content-2-photos-mobile'>
                                            {
                                                images_jp.map((image, index) => {
                                                    return (
                                                        <div className='location-content-2-photo-wrapper location-content-2-photo-wrapper-mobile' key={`location-img-${index}`}>
                                                            <img className='location-content-2-photo location-content-2-photo-mobile' src={image} alt={image} key={image}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                overwriteImageBlock={
                                    <div className='location-content-2-map location-content-2-map-mobile'>
                                        <iframe src={addressIframeJP} width="320" height="280" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                                        <a className="location-content-2-address" target="_blank" href={addressLinkJP}>
                                            <FormattedMessage id="app.locations.content_2.map_view"
                                                defaultMessage="Location"
                                                description="Location"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </a>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className="location-content-4-banner location-content-4-banner-mobile">
                        <img className="location-banner-bg-1 location-banner-bg-1-mobile" src={LocationBannerBg1_m}/>
                        <img className="location-banner-bg-2 location-banner-bg-2-mobile" src={LocationBannerBg2_m}/>
                        <img className="location-banner-bg-3 location-banner-bg-3-mobile" src={LocationBannerBg3_m}/>
                    </div>
                    <div className="content-wrapper-mobile">
                        <div className='location-content-4 location-content-4-mobile'>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.locations.content_4.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div className="location-btn location-btn-mobile">
                                        <div className="button default">
                                            <a className="button-link default" onClick={() => openForm()}>
                                                <FormattedMessage id="app.locaitons.content_4.button"
                                                    defaultMessage="Send Us a message"
                                                    description="Send Us a message"
                                                    values={{what: 'react-intl'}}/>
                                            </a>
                                        </div>
                                    </div>
                                }
                                contentWidth="100%"
                                reverse
                            />
                        </div>
                    </div>

                    <div id="overlay" onClick={() => closeForm()}></div>
                    <div id="popup" className='lightbox popup popup-mobile'>
                        <div className="lightbox-cross" onClick={()=> closeForm()}>
                            <AiOutlineCloseCircle/>
                        </div>
                        <ContactForm closeForm={() => closeForm()} isMobile={props.isMobile}/>
                    </div>
                </>
            :
                <>
                    <div className='location-header-background'>
                        <img src={HeaderBg} className="location-header-bg" alt="location-header-background"/>
						<img className="location-header-logo" src={HeaderLogo} />
					</div>
                    <div className="content-wrapper">
                        <div className='location-content-1'>
                            <ImageTextBlock
                                contentTitle={<FormattedMessage id="app.locations.content_1.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_1.content"
                                            defaultMessage="About us"
                                            description="About us"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </div>
                                }
                                contentWidth="100%"
                                reverse
                            />
                        </div>
                    </div>

                    <div className="content-wrapper location-content-2-wrapper">
                        <div className='location-content-2'>
                            <ImageTextBlock
                                contentTitle={<FormattedMessage id="app.locations.content_2.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_2.content"
                                            defaultMessage="Location"
                                            description="Location"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                        <a className="location-content-2-email" href="mailto:info@beehire.co">info@beehire.co</a>

                                        <div className='location-content-2-photos'>
                                            {
                                                images_hk.map((image, index) => {
                                                    return (
                                                        <div className='location-content-2-photo-wrapper' key={`location-img-${index}`}>
                                                            <img className='location-content-2-photo' src={image} alt={image} key={image}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                overwriteImageBlock={
                                    <div className='location-content-2-map'>
                                        <iframe src={addressIframeHK} width="386" height="301" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                                        <a className="location-content-2-address" target="_blank" href={addressLinkHK}>
                                            <FormattedMessage id="app.locations.content_2.map_view"
                                                defaultMessage="Location"
                                                description="Location"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </a>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className="content-wrapper location-content-2-wrapper">
                        <div className='location-content-2 location-content-zero-top'>
                            <ImageTextBlock
                                contentTitle={<FormattedMessage id="app.locations.content_3.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_3.content"
                                            defaultMessage="Location"
                                            description="Location"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                        <a className="location-content-2-email" href="mailto:info@beehire.co">info@beehire.co</a>

                                        <div className='location-content-2-photos'>
                                            {
                                                images_tw.map((image, index) => {
                                                    return (
                                                        <div className='location-content-2-photo-wrapper' key={`location-img-${index}`}>
                                                            <img className='location-content-2-photo' src={image} alt={image} key={image}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                overwriteImageBlock={
                                    <div className='location-content-2-map'>
                                        <iframe src={addressIframeTW} width="386" height="301" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        <a className="location-content-2-address" target="_blank" href={addressLinkTW}>
                                            <FormattedMessage id="app.locations.content_2.map_view"
                                                defaultMessage="Location"
                                                description="Location"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </a>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className="content-wrapper location-content-2-wrapper">
                        <div className='location-content-2 location-content-zero-top'>
                            <ImageTextBlock
                                contentTitle={<FormattedMessage id="app.locations.content_5.title"
                                    defaultMessage="Location TW"
                                    description="Location TW"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.locations.content_5.content"
                                            defaultMessage="Location"
                                            description="Location"
                                            values={{b: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                        <a className="location-content-2-email" href="mailto:info@beehire.co">info@beehire.co</a>

                                        <div className='location-content-2-photos'>
                                            {
                                                images_jp.map((image, index) => {
                                                    return (
                                                        <div className='location-content-2-photo-wrapper' key={`location-img-${index}`}>
                                                            <img className='location-content-2-photo' src={image} alt={image} key={image}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                overwriteImageBlock={
                                    <div className='location-content-2-map'>
                                        <iframe src={addressIframeJP} width="386" height="301" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        <a className="location-content-2-address" target="_blank" href={addressLinkJP}>
                                            <FormattedMessage id="app.locations.content_2.map_view"
                                                defaultMessage="Location JP"
                                                description="Location JP"
                                                values={{b: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </a>
                                    </div>
                                }
                                reverse
                            />
                        </div>
                    </div>

                    <div className="location-content-4-banner">
                        <img className="location-banner-bg-1" src={LocationBannerBg1}/>
                        <img className="location-banner-bg-2" src={LocationBannerBg2}/>
                        <img className="location-banner-bg-3" src={LocationBannerBg3}/>
                    </div>
                    <div className="content-wrapper">
                        <div className='location-content-4'>
                            <ImageTextBlock
                                contentTitle={<FormattedMessage id="app.locations.content_4.title"
                                    defaultMessage="Location"
                                    description="Location"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div className="location-btn">
                                        <div className="button default">
                                            <a className="button-link default" onClick={() => openForm()}>
                                                <FormattedMessage id="app.locaitons.content_4.button"
                                                    defaultMessage="Send Us a message"
                                                    description="Send Us a message"
                                                    values={{what: 'react-intl'}}/>
                                            </a>
                                        </div>
                                    </div>
                                }
                                contentWidth="100%"
                                reverse
                            />
                        </div>
                    </div>

                    <div id="overlay" onClick={() => closeForm()}></div>
                    <div id="popup" className='lightbox popup'>
                        <div className="lightbox-cross" onClick={()=> closeForm()}>
                            <AiOutlineCloseCircle/>
                        </div>
                        <ContactForm closeForm={() => closeForm()} isMobile={props.isMobile}/>
                    </div>
                </>
            }
        </main>
    );
}