import React, { useState, useEffect, useRef  } from 'react';
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import JobsAlertLightbox  from '../components/JobsAlertLightbox/JobsAlertLightbox';
import { FaBell } from 'react-icons/fa';

import HeaderBg from '../images/header_bg.png';
import HeaderBg_m from '../images/mobile/header_bg.png';
import HeaderLogo from '../images/header_logo.png';
import '../styles/App.css';

export const Jobs = (props) => {

    const [hashtags, setHashTags] = useState([
        { tagId: 1, tagName: "Front-end" },
        { tagId: 2, tagName: "Back-end" },
        { tagId: 3, tagName: "System Infrastructure" },
        { tagId: 4, tagName: "AI & Machine Learning" },
        { tagId: 5, tagName: "iOS" },
        { tagId: 6, tagName: "Android" },
        { tagId: 7, tagName: "Blockchain" }
    ]);

    const [selectedTags, setSelectedTags] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [canUpload, setCanUpload] = useState(false);
    const hashTags = useRef();

    useEffect(() => {
        let mounted = true;
        window.scrollTo(0, 0);
        props.setLink("Jobs");
        try {
            props.instance.get(`/jobs/search`, { params: { job_status: 1 }}).then(response =>
            {
                if (mounted)
                {
                    let result = [];
                    for (let i = 0; result.length < 10; i ++)
                    {
                        let color = Math.floor(Math.random()*3);
                        response.data.data[i].color = color;
                        if (response.data.data[i].company_slug != "27502263") {
                            result.push(response.data.data[i]);
                        }
                    };
                    setJobs(result);
                }
            })
        } catch (err) {
            console.error(err);
        }

        return () => mounted = false;
    }, []);


    const resetSelectedTags = () => {
        setSelectedTags([...[]]);
        hashtags.map((tag, index) => {
            return hashTags.current.children[index].className = props.isMobile ? "hashtag-mobile" : "hashtag"
        })
    }

    const selectTag = (e, tag) => {
        let tempTags = selectedTags;
        if (!selectedTags.includes(tag)) {
            tempTags.push(tag);
            e.target.className = props.isMobile ? "hashtag-mobile tag-selected" : "hashtag tag-selected"
        } else {
            tempTags.splice(tempTags.indexOf(tag), 1);
            e.target.className = props.isMobile ? "hashtag-mobile" : "hashtag"
        }

        if (tempTags.length !== selectedTags.length)
        {
            setSelectedTags(tempTags);
        }
    }

    const [isOpen, setOpen] = useState(false);

    const toggleLightbox = (isViewmore) => {
        if (isOpen)
        {
            setOpen(false);
            setCanUpload(false);
            setSelectedJob({});
        }
        else
        {
            window.scrollTo({top: 0, behavior: 'smooth'});
            if (isViewmore)
            {
                setCanUpload(false);
            }
            else
            {
                setCanUpload(true);
            }
            setOpen(true);
            setSelectedJob();
        }
    }

    const splitValue = (value) => {
        if (value != null)
        {
            return value.split(',');
        }
        else
        {
            return value;
        }
    }

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            { props.isMobile?
                <>
                    <div className='header-background jobs-header-background-mobile'>
                        <img src={HeaderBg_m} className="job-header-bg-mobile" alt="job-header-background"/>
                    </div>
                    <div className="content-wrapper-mobile jobs-content-wrapper-mobile">
                        <div className='jobs-content-1 jobs-content-1-mobile'>
                            <div className="breadcrumb">
                                <FormattedMessage id="app.breadcrumb.home"
                                    defaultMessage="Home page"
                                    description="Home page"
                                    values={{what: 'react-intl'}}
                                />
                                \ <FormattedMessage id="app.breadcrumb.jobs"
                                    defaultMessage="Job Seeking"
                                    description="Job Seeking"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='jobs-title job-title-mobile'>
                                <FormattedMessage id="app.jobs.title"
                                    defaultMessage="Find Jobs by Expertise"
                                    description="Find Jobs by Expertise"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className='jobs-content jobs-content-mobile'>
                                <FormattedMessage id="app.jobs.content_1.content"
                                    defaultMessage="Find a role that lets you really shine.\nDiscover opportunities, sectors, and connections."
                                    description="Find a role that lets you really shine.\nDiscover opportunities, sectors, and connections."
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <ul className='jobs-hashtags-wrapper jobs-hashtags-wrapper-mobile' ref={hashTags}>
                                {hashtags.map((tag, index) => {
                                    return (
                                        <li className="hashtag-mobile" key={tag.tagName} onClick={(e) => selectTag(e, tag)}>{tag.tagName}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className='content-wrapper-mobile'>
                        <div className='jobs-wrapper jobs-wrapper-mobile'>
                            <div className='jobs-header'>
                                <div className='jobs-subtitle'>
                                    <FormattedMessage id="app.jobs.subtitle_m"
                                        defaultMessage="Recently Jobs in the Market "
                                        description="Recently Jobs in the Market "
                                        values={{what: 'react-intl'}}
                                    />
                                </div>

                                <div className='jobs-alert-button-mobile' onClick={()=> toggleLightbox()}>
                                    <FaBell/>
                                    <div className='jobs-alert-button-text'>
                                        <FormattedMessage id="app.jobs.alert"
                                            defaultMessage="Job Alert"
                                            description="Job Alert"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='jobs-block-wrapper'>
                                {
                                    jobs.map(data => {
                                        return (
                                            <Link className='jobs-block jobs-block-mobile' key={`jobs-block-${data.slug}`} to={`/job_details/${data.slug}`}>
                                                <div className={ data.color === 0 ? "jobs-block-divide divide-pink" : data.color === 1 ? "jobs-block-divide divide-yellow" :"jobs-block-divide divide-cyan"} ></div>
                                                <div className='slider-block-title'>{data.name}</div>
                                                <div className="slider-block-city">{data.city === "" ? "Kowloon": data.city }</div>
                                                <div className="slider-block-exp">
                                                    {data.minimum_experience}-{data.maximum_experience} <FormattedMessage id="app.slider.experience"
                                                        defaultMessage="Slider"
                                                        description="Slider"
                                                        values={{what: 'react-intl'}}/>
                                                </div>
                                                <div className={data.name.length > 30 ? "slider-block-tags-wrapper slider-long-name" : "slider-block-tags-wrapper"}>
                                                    <div className="slider-block-tags">
                                                        {data.custom_fields.map( (field, index) => {
                                                            return (
                                                                index < 3 ?
                                                                    field.value != null ?
                                                                        splitValue(field.value).map((splitedValue) =>
                                                                            <div className="slider-tag" key={`tag-${splitedValue}`}>
                                                                                {splitedValue}
                                                                            </div>
                                                                        )
                                                                    : null
                                                                : null
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="slider-block-footer">
                                                    <div className="slider-block-date">
                                                        <FormattedMessage id="app.slider.posted_by"
                                                            defaultMessage="Post Date"
                                                            description="Post Date"
                                                            values={{what: 'react-intl'}}/>
                                                        {dateFormat(data.updated_on, 'dd mmm yyyy')}
                                                    </div>
                                                    <div className="slider-block-salary">
                                                        ~ ${data.max_annual_salary}
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                                <div className='jobs-block jobs-block-mobile last-mobile' onClick={()=> toggleLightbox(true)}>
                                    <div className='jobs-block-viewmore'>
                                        <FormattedMessage id="app.jobs.viewmore"
                                            defaultMessage="View more"
                                            description="View more"
                                            values={{what: 'react-intl'}}/>
                                    </div>
                                </div>
                            </div>

                            <div className='jobs-footer jobs-footer-mobile'>
                                <div className='jobs-footer-wrapper jobs-footer-wrapper-mobile'>
                                    <FormattedMessage id="app.jobs.footer"
                                        defaultMessage="Jobs alert"
                                        description="Jobs alert"
                                        values={{what: 'react-intl'}}/>
                                    <button className='jobs-footer-notify' onClick={()=> toggleLightbox()}>
                                        <FaBell/>
                                        <FormattedMessage id="app.jobs.footer.notify"
                                            defaultMessage="Notify Me"
                                            description="Notify Me"
                                            values={{what: 'react-intl'}}/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <JobsAlertLightbox
                            isOpen={isOpen}
                            canUpload={canUpload}
                            selectedJob={selectedJob}
                            selectedTags={selectedTags}
                            toggleLightbox={toggleLightbox}
                            resetSelectedTags={resetSelectedTags}
                            instance={props.instance}
                            isMobile={props.isMobile}
                        />
                    </div>
                </>
            :
                <>
                    <div className='jobs-header-background'>
                        <img src={HeaderBg} className="job-header-bg" alt="job-header-background"/>
                        <img className="jobs-header-logo" src={HeaderLogo} alt="job-header-logo"/>
                    </div>
                    <div className='content-wrapper'>
                        <div className='jobs-content-1'>
                            <div className='jobs-title'>
                                <FormattedMessage id="app.jobs.title"
                                    defaultMessage="Find Jobs by Expertise"
                                    description="Find Jobs by Expertise"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='jobs-content'>
                                <FormattedMessage id="app.jobs.content_1.content"
                                    defaultMessage="Find a role that lets you really shine.\nDiscover opportunities, sectors, and connections."
                                    description="Find a role that lets you really shine.\nDiscover opportunities, sectors, and connections."
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <ul className='jobs-hashtags-wrapper' ref={hashTags}>
                                {hashtags.map((tag, index) => {
                                    return (
                                        <li className="hashtag" key={tag.tagName} onClick={(e) => selectTag(e, tag)}>{tag.tagName}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className='jobs-wrapper'>
                        <div className='jobs-header'>
                            <div className='jobs-subtitle'>
                                <FormattedMessage id="app.jobs.subtitle"
                                    defaultMessage="Recently Jobs in the Market "
                                    description="Recently Jobs in the Market "
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className='jobs-alert-button' onClick={()=> toggleLightbox()}>
                                <FaBell/>
                                <div className='jobs-alert-button-text'>
                                    <FormattedMessage id="app.jobs.alert"
                                        defaultMessage="Job Alert"
                                        description="Job Alert"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='jobs-block-wrapper'>
                            {
                                jobs.map(data => {
                                    return (
                                        <Link className='jobs-block' key={`jobs-block-${data.slug}`} to={`/job_details/${data.slug}`}>
                                            <div className={ data.color === 0 ? "jobs-block-divide divide-pink" : data.color === 1 ? "jobs-block-divide divide-yellow" :"jobs-block-divide divide-cyan"} ></div>
                                            <div className='slider-block-title'>{data.name}</div>
                                            <div className="slider-block-city">{data.city === "" ? "Kowloon": data.city }</div>
                                            <div className="slider-block-exp">
                                                {data.minimum_experience}-{data.maximum_experience} <FormattedMessage id="app.slider.experience"
                                                    defaultMessage="Slider"
                                                    description="Slider"
                                                    values={{what: 'react-intl'}}/>
                                            </div>
                                            <div className={data.name.length > 30 ? "slider-block-tags-wrapper slider-long-name" : "slider-block-tags-wrapper"}>
                                                <div className="slider-block-tags">
                                                    {data.custom_fields.map( (field, index) => {
                                                        return (
                                                            index < 3 ?
                                                                field.value != null ?
                                                                    splitValue(field.value).map((splitedValue) =>
                                                                        <div className="slider-tag" key={`job-${splitedValue}`}>
                                                                            {splitedValue}
                                                                        </div>
                                                                    )
                                                                : null
                                                            : null
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="slider-block-footer">
                                                <div className="slider-block-date">
                                                    <FormattedMessage id="app.slider.posted_by"
                                                        defaultMessage="Post Date"
                                                        description="Post Date"
                                                        values={{what: 'react-intl'}}/>
                                                    {dateFormat(data.updated_on, 'dd mmm yyyy')}
                                                </div>
                                                <div className="slider-block-salary">
                                                    ~ ${data.max_annual_salary}
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                            <div className='jobs-block last' onClick={()=> toggleLightbox(true)}>
                                <div className='jobs-block-viewmore'>
                                    <FormattedMessage id="app.jobs.viewmore"
                                        defaultMessage="View more"
                                        description="View more"
                                        values={{what: 'react-intl'}}/>
                                </div>
                            </div>
                        </div>

                        <div className='jobs-footer'>
                            <div className='jobs-footer-wrapper'>
                                <FormattedMessage id="app.jobs.footer"
                                    defaultMessage="Jobs alert"
                                    description="Jobs alert"
                                    values={{what: 'react-intl'}}/>
                                <button className='jobs-footer-notify' onClick={()=> toggleLightbox()}>
                                    <FaBell/>
                                    <FormattedMessage id="app.jobs.footer.notify"
                                        defaultMessage="Notify Me"
                                        description="Notify Me"
                                        values={{what: 'react-intl'}}/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <JobsAlertLightbox
                        isOpen={isOpen}
                        canUpload={canUpload}
                        selectedJob={selectedJob}
                        selectedTags={selectedTags}
                        toggleLightbox={toggleLightbox}
                        resetSelectedTags={resetSelectedTags}
                        instance={props.instance}
                    />
                </>
            }
        </main>
    );
}