import React from 'react';
import './ImageTextBlock.css';
import { useMediaQuery } from 'react-responsive'

/*
    ------------- Mandatory ---------------------
    title: h3 header
    reverse: Control the image or content display sequence
    content: Main Content display text

    ------------- Optional ----------------------
    contentTitle: sup title in content box
    imageSrc: the image source
    overwriteImageBlock: customs style for the image block, that will replace the image
    textColor: text color (default #000 black)
    backgroundImageWidth: default 50% - use it with contentWidth
    contentWidth: default 50% - use it with backgroundImageWidth
*/

export const ImageTextBlock = ({title, contentTitle, content, reverse, imageSrc, overwriteImageBlock, textColor, backgroundImageWidth, contentWidth}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    return (
        <div className="main">
            <div className={isMobile? "title-mobile" : "title"} style={{color: textColor}}>{title}</div>
            <div className={isMobile? reverse ? "block-mobile block-mobile-reverse" : "block-mobile" : reverse ? "block-reverse" : "block"}>
                <div className={isMobile? "block-image-mobile" : "block-image"} style={{width: backgroundImageWidth}}>
                    {imageSrc?
                        <img className="image" src={imageSrc} alt="true"/>
                        :
                        overwriteImageBlock
                    }
                </div>
                <div className={isMobile? "block-content-wrapper-mobile" : "block-content-wrapper"} style={{width: contentWidth}}>
                    <div className={isMobile? "title-mobile" : "title"} style={{color: textColor}}>{contentTitle}</div>
                    <div className={isMobile? "block-content-mobile" : "block-content"}>
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
}