import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './Button.css';

export const Button = ({ButtonTextId, DefaultText, ButtonDesc, ButtonLink, ButtonStyle, ButtonAction}) => {
    return (
        <>
            {
                ButtonStyle === "primary" ?
                    <div className="button primary">
                        <Link className="button-link primary" to={ButtonLink} onClick={ButtonAction? ()=> ButtonAction : null}>
                            <FormattedMessage id={ButtonTextId}
                                defaultMessage={DefaultText}
                                description={ButtonDesc}
                                values={{what: 'react-intl'}}/>
                        </Link>
                    </div>
                : ButtonStyle === "secondary" ?
                    <div className="button secondary">
                        <Link className="button-link" to={ButtonLink} onClick={ButtonAction? ()=> ButtonAction : null}>
                            <FormattedMessage id={ButtonTextId}
                                defaultMessage={DefaultText}
                                description={ButtonDesc}
                                values={{what: 'react-intl'}}/>
                        </Link>
                    </div>
                :
                    <div className="button default">
                        <Link className="button-link default" to={ButtonLink} onClick={ButtonAction? ()=> ButtonAction : null}>
                            <FormattedMessage id={ButtonTextId}
                                defaultMessage={DefaultText}
                                description={ButtonDesc}
                                values={{what: 'react-intl'}}/>
                        </Link>
                    </div>
            }
        </>
    );
}