import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ImageViewer from 'react-simple-image-viewer';
import { Link } from 'react-router-dom';

import LogoCheckBox from '../images/logo_checkbox.png';

import { JobsSlider } from '../components/JobsSlider/JobsSlider';
import { ImageTextBlock } from '../components/ImageTextBlock/ImageTextBlock';
import HeaderLogo from '../images/join-header-logo.png';
import HeaderLogo_m from '../images/mobile/join-header-logo.png';
import Join2Bg from '../images/join-content-2-bg.png';
import PhotoImage1 from '../images/join-content-2-img-1.png';
import PhotoImage2 from '../images/join-content-2-img-2.png';
import PhotoImage3 from '../images/join-content-2-img-3.png';
import PhotoImage1_m from '../images/mobile/join-content-2-img-1.png';
import PhotoImage2_m from '../images/mobile/join-content-2-img-2.png';
import PhotoImage3_m from '../images/mobile/join-content-2-img-3.png';
import Join4Bg from '../images/join-content-4-bg.png';
import Join4Bg_m from '../images/mobile/join-content-4-bg.png';
import JobsAlertLightbox  from '../components/JobsAlertLightbox/JobsAlertLightbox';
import HeaderBg_m from '../images/mobile/header_bg.png';
import HeaderBg from '../images/header_bg.png';

export const JoinUs = (props) => {
	const [jobs, setJobs] = useState([]);

    useEffect(() => {
		window.scrollTo(0, 0);
		if (props.isScroll)
		{
			executeScroll();
			props.setNeedScroll(false);
		}
    	props.setLink("JoinUs");
        let mounted = true;
        try {
            props.instance.get(`/jobs/search`, { params: { company_name: "Beehire Personnel Limited"}}).then(response =>
            {
                if (mounted)
                {
                    let result = [];
                    for (let i = 0; i < response.data.data.length; i ++)
                    {
                        result.push(response.data.data[i]);
                    };
                    setJobs(result);
                }
            })
        } catch (err) {
            console.error(err);
        }
    }, []);

	const scroller = useRef(null);
    const executeScroll = () => scroller.current.scrollIntoView({behavior: 'smooth'});

	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const images = [
		PhotoImage1,
		PhotoImage2,
		PhotoImage3
	];

	const images_m = [
		PhotoImage1_m,
		PhotoImage2_m,
		PhotoImage3_m
	];

	const albumLink = "/";

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [canUpload, setCanUpload] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const resetSelectedTags = () => {
    }

    const toggleLightbox = (isViewmore) => {
        if (isOpen)
        {
            setOpen(false);
            setCanUpload(false);
            setSelectedJob({});
        }
        else
        {
            window.scrollTo({top: 0, behavior: 'smooth'});
            if (isViewmore)
            {
                setCanUpload(false);
            }
            else
            {
                setCanUpload(true);
            }
            setOpen(true);
            setSelectedJob();
        }
    }

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            {props.isMobile?
                <>
                    <div className='join-header-background join-header-background-mobile'>
                        <img src={HeaderBg_m} className="join-header-bg-mobile" alt="join-header-background"/>
						<img className="join-header-logo join-header-logo-mobile" src={HeaderLogo_m} />
					</div>
                    <div className="content-wrapper-mobile">
                        <div className='join-our-team-content-1 join-our-team-content-1-mobile'>
							<div className="breadcrumb">
                                <FormattedMessage id="app.breadcrumb.home"
                                    defaultMessage="Home page"
                                    description="Home page"
                                    values={{what: 'react-intl'}}
                                />
                                \ <FormattedMessage id="app.breadcrumb.join"
                                    defaultMessage="Join Our Team"
                                    description="Join Our Team"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <ImageTextBlock
                                title={<FormattedMessage id="app.join_our_team.content_1.title"
                                    defaultMessage="Join Our Team"
                                    description="Join Our Team"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.join_our_team.content_1.content"
                                            defaultMessage="Join Our Team"
                                            description="Join Our Team"
                                            values={{what: 'react-intl'}}

                                        />
                                        <div className="talnet-btn">
											<div className="button default">
												<Link className="button-link default" to="#" onClick={()=> executeScroll()}>
													<FormattedMessage id="app.join_our_team.content_1.button"
														defaultMessage="Looking for talnet"
														description="Looking for talnet"
														values={{what: 'react-intl'}}/>
												</Link>
											</div>
                                        </div>
                                    </div>
                                }
                                reverse
                                contentWidth="100%"
                                backgroundImageWidth="30%"
                            />
                        </div>
                    </div>

                    <div className="content-wrapper-mobile join-our-team-content-2-wrapper join-our-team-content-2-wrapper-mobile">
                        <div className='join-our-team-content-2-title join-our-team-content-2-title-mobile'>
                            <FormattedMessage id="app.join_our_team.content_2.title"
                                defaultMessage="Join Our Team Page"
                                description="Block 2 Title"
                                values={{what: 'react-intl'}}
                            />
                        </div>

						<div className='join-out-team-content-2 join-out-team-content-2-mobile'>
							<div className='join-our-team-content-2-background join-our-team-content-2-background-mobile'>
								<div className='photo-album-wrapper photo-album-wrapper-mobile'>
									<div className="join-our-team-photo-1 join-our-team-photo-1-mobile">
										<img
											src={ images_m[0] }
												onClick={ () => openImageViewer(0) }
												key={`${images[0]}-0`}
												alt=""
										/>
									</div>
									<div className='photo-ablum-sub-wrapper'>
										<img
											src={ images_m[1] }
												onClick={ () => openImageViewer(1) }
												className="join-our-team-photo-2 join-our-team-photo-2-mobile"
												key={`${images[0]}-1`}
												alt=""
										/>
										<img
											src={ images_m[2] }
												onClick={ () => openImageViewer(2) }
												className="join-our-team-photo-3 join-our-team-photo-3-mobile"
												key={`${images[0]}-2`}
												alt=""
										/>
									</div>

								</div>

								{isViewerOpen && (
									<ImageViewer
									src={ images }
									currentIndex={ currentImage }
									disableScroll={ false }
									closeOnClickOutside={ true }
									onClose={ closeImageViewer }
									/>
								)}

							</div>


							<div className='join-our-team-content-2-content-wrapper join-our-team-content-2-content-wrapper-mobile'>
								<div className='join-our-team-content-2-content'>
									<FormattedMessage id="app.join_our_team.content_2.content"
										defaultMessage="View More Article"
										description="View More Article"
										values={{what: 'react-intl'}}
									/>
								</div>

								<ul className='content-list'>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_1"
											defaultMessage="List 1"
											description="content 2 Content list 1"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_2"
											defaultMessage="List 2"
											description="content 2 Content list 2"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_3"
											defaultMessage="List 3"
											description="content 2 Content list 3"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_4"
											defaultMessage="List 4"
											description="content 2 Content list 4"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_5"
											defaultMessage="List 5"
											description="content 2 Content list 5"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_6"
											defaultMessage="List 6"
											description="content 2 Content list 6"
											values={{what: 'react-intl'}} />
										</div>
									</li>
								</ul>

								<div className='photo-album-footer'>
									<Link  to={albumLink}>
											<FormattedMessage id="app.join_our_team.more_photos"
												defaultMessage="View More Article"
												description="View More Article"
												values={{what: 'react-intl'}}
											/>
									</Link>
								</div>
							</div>
						</div>
                    </div>

					<div className="content-wrapper-mobile join-our-team-content-3-wrapper">
						<div className='join-our-team-content-3 join-our-team-content-3-mobile'>
                            <div className='title-mobile' ref={scroller}>
                                <FormattedMessage id="app.join_our_team.jobpost"
                                    defaultMessage="Recently Job Post"
                                    description="Recently Job Post"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
							<div className='join-slider-mobile'>
								<JobsSlider sliderData={jobs} isMobile={props.isMobile}/>
							</div>

							<div className='join-content-link-footer join-content-3-footer'>
								<Link  to="/jobs">
										<FormattedMessage id="app.join_our_team.more_jobs"
											defaultMessage="View More Jobs"
											description="View More Jobs"
											values={{what: 'react-intl'}}
										/>
								</Link>
							</div>
                        </div>
					</div>

					<div className='join-content-4-background join-content-4-background-mobile'>
						<img src={Join4Bg_m} />
					</div>
					<div className="content-wrapper-mobile join-our-team-content-4-wrapper join-our-team-content-4-wrapper-mobile">
						<div className='join-our-team-content-4'>
							<div className='join-content-4-title'>
								<FormattedMessage id="app.join_our_team.content_4.title"
										defaultMessage="Recently Job Post"
										description="Recently Job Post"
										values={{what: 'react-intl'}}
								/>
							</div>
							<div className="join-btn">
								<div className="button primary">
									<Link className="button-link primary" to="#" onClick={()=> toggleLightbox()}>
										<FormattedMessage id="app.join_our_team.content_4.btn"
											defaultMessage="Join Our Team"
											description="Join Our Team"
											values={{what: 'react-intl'}}/>
									</Link>
								</div>
							</div>
                        </div>
					</div>

					<JobsAlertLightbox
						isOpen={isOpen}
						canUpload={canUpload}
						selectedJob={selectedJob}
						selectedTags={selectedTags}
						toggleLightbox={toggleLightbox}
						resetSelectedTags={resetSelectedTags}
						instance={props.instance}
						isMobile={props.isMobile}
					/>
                </>
            :
                <>
					<div className='join-header-background'>
                        <img src={HeaderBg} className="join-header-bg" alt="join-header-background"/>
						<img className="join-header-logo" src={HeaderLogo} />
					</div>
                    <div className="content-wrapper">
                        <div className='join-our-team-content-1'>
                            <ImageTextBlock
                                contentTitle={<FormattedMessage id="app.join_our_team.content_1.title"
                                    defaultMessage="Join Our Team"
                                    description="Join Our Team"
                                    values={{what: 'react-intl'}}
                                />}
                                content={
                                    <div>
                                        <FormattedMessage id="app.join_our_team.content_1.content"
                                            defaultMessage="Join Our Team"
                                            description="Join Our Team"
                                            values={{what: 'react-intl'}}

                                        />
                                        <div className="talnet-btn">
											<div className="button default">
												<Link className="button-link default" to="#" onClick={()=> executeScroll()}>
													<FormattedMessage id="app.join_our_team.content_1.button"
														defaultMessage="Looking for talnet"
														description="Looking for talnet"
														values={{what: 'react-intl'}}/>
												</Link>
											</div>
                                        </div>
                                    </div>
                                }
                                reverse
                                contentWidth="100%"
                                backgroundImageWidth="30%"
                            />
                        </div>
                    </div>

					<div className='join-content-2-background'>
						<img src={Join2Bg} />
					</div>

                    <div className="content-wrapper join-our-team-content-2-wrapper">
                        <div className='join-our-team-content-2-title'>
                            <FormattedMessage id="app.join_our_team.content_2.title"
                                defaultMessage="Join Our Team Page"
                                description="Block 2 Title"
                                values={{what: 'react-intl'}}
                            />
                        </div>

						<div className='join-out-team-content-2'>
							<div className='join-our-team-content-2-background'>
								<div className='photo-album-wrapper'>
									<img
										src={ images[0] }
											onClick={ () => openImageViewer(0) }
											className="join-our-team-photo join-our-team-photo-1"
											key={`${images[0]}-0`}
											alt=""
									/>
									<img
										src={ images[1] }
											onClick={ () => openImageViewer(1) }
											className="join-our-team-photo join-our-team-photo-2"
											key={`${images[0]}-1`}
											alt=""
									/>
									<img
										src={ images[2] }
											onClick={ () => openImageViewer(2) }
											className="join-our-team-photo join-our-team-photo-3"
											key={`${images[0]}-2`}
											alt=""
									/>

								</div>

								{isViewerOpen && (
									<ImageViewer
									src={ images }
									currentIndex={ currentImage }
									disableScroll={ false }
									closeOnClickOutside={ true }
									onClose={ closeImageViewer }
									/>
								)}

							</div>


							<div className='join-our-team-content-2-content-wrapper'>
								<div className='join-our-team-content-2-content'>
									<FormattedMessage id="app.join_our_team.content_2.content"
										defaultMessage="View More Article"
										description="View More Article"
										values={{what: 'react-intl'}}
									/>
								</div>

								<ul className='content-list'>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_1"
											defaultMessage="List 1"
											description="content 2 Content list 1"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_2"
											defaultMessage="List 2"
											description="content 2 Content list 2"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_3"
											defaultMessage="List 3"
											description="content 2 Content list 3"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_4"
											defaultMessage="List 4"
											description="content 2 Content list 4"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_5"
											defaultMessage="List 5"
											description="content 2 Content list 5"
											values={{what: 'react-intl'}} />
										</div>
									</li>
									<li className='content-list-li join-our-team-li'>
										<img className="content-list-image" src={LogoCheckBox} alt="checkbox"/>
										<div className='content-list-text'>
											<FormattedMessage id="app.join_our_team.content_2.content_list_6"
											defaultMessage="List 6"
											description="content 2 Content list 6"
											values={{what: 'react-intl'}} />
										</div>
									</li>
								</ul>

								<div className='photo-album-footer'>
									<Link  to={albumLink}>
											<FormattedMessage id="app.join_our_team.more_photos"
												defaultMessage="View More Article"
												description="View More Article"
												values={{what: 'react-intl'}}
											/>
									</Link>
								</div>
							</div>
						</div>
                    </div>

                    <div className="content-wrapper join-our-team-content-3-wrapper" >
						<div className='join-our-team-content-3' ref={scroller}>
                            <div className='title'>
                                <FormattedMessage id="app.join_our_team.jobpost"
                                    defaultMessage="Recently Job Post"
                                    description="Recently Job Post"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
							<div className='join-slider'>
                            	<JobsSlider sliderData={jobs} isMobile={props.isMobile}/>
							</div>
                        </div>
					</div>

					<div className='join-content-4-background'>
						<img src={Join4Bg} className="join-our-team-content-4-background-img"/>
					</div>

					<div className="content-wrapper join-our-team-content-4-wrapper">
						<div className='join-our-team-content-4'>
							<div className='join-content-4-title'>
								<FormattedMessage id="app.join_our_team.content_4.title"
										defaultMessage="Recently Job Post"
										description="Recently Job Post"
										values={{what: 'react-intl'}}
								/>
							</div>
							<div className="join-btn">
								<div className="button primary">
									<Link className="button-link primary" to="#" onClick={()=> toggleLightbox()}>
										<FormattedMessage id="app.join_our_team.content_4.btn"
											defaultMessage="Join Our Team"
											description="Join Our Team"
											values={{what: 'react-intl'}}/>
									</Link>
								</div>
							</div>
                        </div>
					</div>
					<JobsAlertLightbox
						isOpen={isOpen}
						canUpload={canUpload}
						selectedJob={selectedJob}
						selectedTags={selectedTags}
						toggleLightbox={toggleLightbox}
						resetSelectedTags={resetSelectedTags}
						instance={props.instance}
						isMobile={props.isMobile}
					/>
                </>
            }
        </main>
    );
}