import React, { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import HeaderBg from '../images/header_bg.png';
import HeaderBg_m from '../images/mobile/header_bg.png';

export const Privacy = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        props.setLink("Privacy");
    }, []);

    const SourcesReference = (source) => {
        return  (
                    <div>
                        <div style={{marginTop: "20px", fontWeight: "bold"}}>{source.title}</div>
                        <div style={{marginTop: "20px"}}>{source.content}</div>
                    </div>
                )
    }

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            {props.isMobile?
                <>
                    <div className='terms-header-background terms-header-background-mobile'>
                        <img src={HeaderBg_m} className="terms-header-bg-mobile" alt="terms-header-background"/>
                    </div>

                    <div className="content-wrapper-mobile terms-content-wrapper-mobile">
                        <div className="breadcrumb">
                            <FormattedMessage id="app.breadcrumb.home"
                                defaultMessage="Home page"
                                description="Home page"
                                values={{what: 'react-intl'}}
                            />
                            \ <FormattedMessage id="app.breadcrumb.privacy"
                                defaultMessage="Privacy"
                                description="Privacy"
                                values={{what: 'react-intl'}}
                            />
                        </div>
                        <div className='terms-title-mobile'>
                            <FormattedMessage id="app.privacy.title"
                                defaultMessage="Privacy Statement"
                                description="Privacy Statement"
                                values={{what: 'react-intl'}}
                            />
                        </div>

                        <div className='content-wrapper-mobile terms-content terms-content-mobile'>
                            <FormattedMessage id="app.privacy.content"
                                defaultMessage="Privacy"
                                description="Privacy"
                                values={{what: 'react-intl'}}
                            />

                            {SourcesReference({title: "Data Collection and Use", content: "We may require client/candidate to provide personal data in order to provide our services. Failure to do so may result in our inability to fulfill the requested services. Please be assured that we will only collect and use your personal data for the purposes stated in our Privacy Policy and in compliance with applicable data protection laws. Our consultants only use company authorized devices and contact methods to communicate with our candidates and clients, ensuring the security and confidentiality of personal information."})}
                            {SourcesReference({title: "Privacy Policy Acceptance and Consent", content: "When client/candidate make purchases or communicate with Beehire, we will request their acceptance or consent to the terms of our Privacy Policy. By using or accessing this website, client/candidate are deemed to have consented to our Privacy Policy. If client/candidate provides personal data of third parties, they must obtain the relevant third party's consent to provide their personal data to us. We collect and use personal data in accordance with our Privacy Policy and applicable data protection laws."})}
                            {SourcesReference({title: "Commitment to Confidentiality", content: "We prioritize the protection of personal data provided by candidates. We store this information securely on our databases and files, which are accessible to our consultants worldwide. We strictly limit the disclosure of applicants' personal details to clients until prior to the interview stage, and only with the candidates' approval. Our services are completely free of charge for candidates."})}
                            {SourcesReference({title: "Compliance with Data Management Standards", content: "We are licensed as an employment agency by the relevant government departments in each of our countries of operation. We adhere to the data management standards required by personnel agencies in each country. The relevant departments may access our data at any time to ensure compliance with these standards."})}
                            {SourcesReference({title: "Candidates' Rights to Data Management", content: "We respect candidates' rights to manage their personal data. Candidates can contact us to update their data from our databases. We may ask for identity verification and additional information to ensure accuracy. We may refuse requests where legally permitted, but will provide reasons for doing so. If there is a dispute about the accuracy of the data, we will make a note on the personal data. Candidates may request that we stop using their information, but we may not be able to comply with requests for deletion of files due to compliance reasons."})}
                            {SourcesReference({title: "Contacting Candidates and Collecting Personal Data", content: "We may contact registered candidates to determine their current employment status or offer vacant positions. We collect personal data, including contact information, work history, qualifications, and more from CVs and interviews. Reference letter may also be collected if a candidate is being considered for a specific position."})}
                            {SourcesReference({title: "Verification of Candidate Information", content: "We may verify information provided voluntarily by candidates, such as academic qualifications and previous employment records, by contacting issuing authorities and Reference letter."})}
                            {SourcesReference({title: "Data Collection and Use by the Company", content: "We collect information on clients and potential clients for the purposes of matching candidates to job orders, business solicitation, and sending candidate details, information, and newsletters. Third parties may be employed securely to assist with these activities. Companies can request removal or updates by emailing info@beehire.co."})}
                            {SourcesReference({title: "Use of Cookies", content: "We use cookies to remember your name, email address, and website when leaving comments on our site. This is for your convenience and avoids the need to enter your details again for subsequent comments. These cookies last for one year and comply with our Privacy Policy and data protection laws."})}</div>
                    </div>
                </>
            :
                <>
                    <div className='terms-header-background'>
                        <img src={HeaderBg} className="terms-header-bg" alt="terms-header-background"/>
                    </div>
                    <div className="content-wrapper">
                        <div className='terms-title'>
                            <FormattedMessage id="app.privacy.title"
                                defaultMessage="Privacy Statement"
                                description="Privacy Statement"
                                values={{what: 'react-intl'}}
                            />
                        </div>
                    </div>
                    <div className='content-wrapper terms-content'>
                        <FormattedMessage id="app.privacy.content"
                            defaultMessage="Privacy"
                            description="Privacy"
                            values={{what: 'react-intl'}}
                        />

                            {SourcesReference({title: "Data Collection and Use", content: "We may require client/candidate to provide personal data in order to provide our services. Failure to do so may result in our inability to fulfill the requested services. Please be assured that we will only collect and use your personal data for the purposes stated in our Privacy Policy and in compliance with applicable data protection laws. Our consultants only use company authorized devices and contact methods to communicate with our candidates and clients, ensuring the security and confidentiality of personal information."})}
                            {SourcesReference({title: "Privacy Policy Acceptance and Consent", content: "When client/candidate make purchases or communicate with Beehire, we will request their acceptance or consent to the terms of our Privacy Policy. By using or accessing this website, client/candidate are deemed to have consented to our Privacy Policy. If client/candidate provides personal data of third parties, they must obtain the relevant third party's consent to provide their personal data to us. We collect and use personal data in accordance with our Privacy Policy and applicable data protection laws."})}
                            {SourcesReference({title: "Commitment to Confidentiality", content: "We prioritize the protection of personal data provided by candidates. We store this information securely on our databases and files, which are accessible to our consultants worldwide. We strictly limit the disclosure of applicants' personal details to clients until prior to the interview stage, and only with the candidates' approval. Our services are completely free of charge for candidates."})}
                            {SourcesReference({title: "Compliance with Data Management Standards", content: "We are licensed as an employment agency by the relevant government departments in each of our countries of operation. We adhere to the data management standards required by personnel agencies in each country. The relevant departments may access our data at any time to ensure compliance with these standards."})}
                            {SourcesReference({title: "Candidates' Rights to Data Management", content: "We respect candidates' rights to manage their personal data. Candidates can contact us to update their data from our databases. We may ask for identity verification and additional information to ensure accuracy. We may refuse requests where legally permitted, but will provide reasons for doing so. If there is a dispute about the accuracy of the data, we will make a note on the personal data. Candidates may request that we stop using their information, but we may not be able to comply with requests for deletion of files due to compliance reasons."})}
                            {SourcesReference({title: "Contacting Candidates and Collecting Personal Data", content: "We may contact registered candidates to determine their current employment status or offer vacant positions. We collect personal data, including contact information, work history, qualifications, and more from CVs and interviews. Reference letter may also be collected if a candidate is being considered for a specific position."})}
                            {SourcesReference({title: "Verification of Candidate Information", content: "We may verify information provided voluntarily by candidates, such as academic qualifications and previous employment records, by contacting issuing authorities and Reference letter."})}
                            {SourcesReference({title: "Data Collection and Use by the Company", content: "We collect information on clients and potential clients for the purposes of matching candidates to job orders, business solicitation, and sending candidate details, information, and newsletters. Third parties may be employed securely to assist with these activities. Companies can request removal or updates by emailing info@beehire.co."})}
                            {SourcesReference({title: "Use of Cookies", content: "We use cookies to remember your name, email address, and website when leaving comments on our site. This is for your convenience and avoids the need to enter your details again for subsequent comments. These cookies last for one year and comply with our Privacy Policy and data protection laws."})}
                    </div>
                </>
            }
        </main>
    );
}