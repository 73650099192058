import React, { useState, useEffect, useRef  } from 'react';
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
import { useParams, Link } from "react-router-dom";

import { Button } from '../components/Button/Button';
import { ImageTextBlock } from '../components/ImageTextBlock/ImageTextBlock';
import JobsAlertLightbox  from '../components/JobsAlertLightbox/JobsAlertLightbox';
import { FaBell } from 'react-icons/fa';
import HeaderBg_m from '../images/mobile/header_bg.png';
import HeaderBg from '../images/header_bg.png';
import HeaderLogo from '../images/header_logo.png';
import '../styles/App.css';

export const JobDetails = (props) => {
    props.setLink("Jobs");
    const [job, setJob] = useState([]);
    const [company, setCompany] = useState([]);
    const { slug } = useParams();
    const [selectedTags, setSelectedTags] = useState([]);
    const hashTags = useRef();

    const [hashtags, setHashTags] = useState([
        { tagId: 1, tagName: "Front-end" },
        { tagId: 2, tagName: "Back-end" },
        { tagId: 3, tagName: "System Infrastructure" },
        { tagId: 4, tagName: "AI & Machine Learning" },
        { tagId: 5, tagName: "iOS" },
        { tagId: 6, tagName: "Android" },
        { tagId: 7, tagName: "Blockchain" }
    ]);

    const selectTag = (e, tag) => {
        let tempTags = selectedTags;
        if (!selectedTags.includes(tag)) {
            tempTags.push(tag);
            e.target.className = "hashtag tag-selected"
        } else {
            tempTags.splice(tempTags.indexOf(tag), 1);
            e.target.className = "hashtag"
        }

        if (tempTags.length !== selectedTags.length)
        {
            setSelectedTags(tempTags);
        }
    }

    const copyLink = (link) => {
        navigator.clipboard.writeText(link);
    }

    const getJobCustomFields = (custom_fields, type) =>
    {
        var text = "";
        custom_fields.map(field => {
            if (field.field_id == 4 && type == "benefits")
            {
                text = field.value;
            }
            else if (field.field_id == 1 && type == "industry")
            {
                text = field.value;
            }
            else if (field.field_id == 5 && type == "specialization")
            {
                text = field.value;
            }
            else if (field.field_id == 6 && type == "jobtype")
            {
                text = field.value;
            }
        })
        return text;
    }

    useEffect(() => {
		window.scrollTo(0, 0);
        let mounted = true;
        try {
            props.instance.get(`/jobs/${slug}`, { params: { job_status: 1 }}).then(response =>
            {
                if (mounted)
                {
                    setJob(response.data)
                    props.instance.get(`/companies/${response.data.company_slug}`).then(response =>
                    {
                        setCompany(response.data);
                    })
                }
            })
        } catch (err) {
            console.error(err);
        }

        return () => mounted = false;
    }, []);

    return (
        <main className={props.isMobile? 'content-mobile': 'content'}>
            { props.isMobile?
                <>
                    <div className='header-background jobs-header-background-mobile'>
                        <img src={HeaderBg_m} alt="job-header-background"/>
                    </div>
                    <div className="content-wrapper-mobile jobs-content-wrapper-mobile">
                        <div className='jobs-content-1 jobs-content-1-mobile'>
                            <div className="breadcrumb">
                                <FormattedMessage id="app.breadcrumb.home"
                                    defaultMessage="Home page"
                                    description="Home page"
                                    values={{what: 'react-intl'}}
                                />
                                \ <FormattedMessage id="app.breadcrumb.jobs"
                                    defaultMessage="Job Seeking"
                                    description="Job Seeking"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='jobs-title job-title-mobile'>
                                <FormattedMessage id="app.jobs.title"
                                    defaultMessage="Find Jobs by Expertise"
                                    description="Find Jobs by Expertise"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className='jobs-content jobs-content-mobile'>
                                <FormattedMessage id="app.jobs.content_1.content"
                                    defaultMessage="Find a role that lets you really shine.\nDiscover opportunities, sectors, and connections."
                                    description="Find a role that lets you really shine.\nDiscover opportunities, sectors, and connections."
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <ul className='jobs-hashtags-wrapper jobs-hashtags-wrapper-mobile' ref={hashTags}>
                                {hashtags.map((tag, index) => {
                                    return (
                                        <li className="hashtag-mobile" key={tag.tagName} onClick={(e) => selectTag(e, tag)}>{tag.tagName}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="content-wrapper-mobile jobs-details-content-1-mobile">
                        <Link to="/jobs" className="job-details-content-2-link">
                            <FormattedMessage id="app.job_details.content_2.link"
                                defaultMessage="Find Jobs by Expertise"
                                description="Find Jobs by Expertise"
                                values={{what: 'react-intl'}}
                            />
                        </Link>
                        <div className="job-details-content-2-title job-details-content-2-title-mobile">
                            {job.name}
                        </div>

                        <div className="job-details-content-2-block-left-title">
                            <FormattedMessage id="app.job_details.content_2.job_description"
                                defaultMessage="Find Jobs by Expertise"
                                description="Find Jobs by Expertise"
                                values={{what: 'react-intl'}}
                            />
                        </div>

                        <div className="job-details-content-2-description">
                            <div className="job-details-content-2-description-wrapper">
                                <div className="job-details-content-2-block-left-content" dangerouslySetInnerHTML={ {__html: job.job_description_text} } />
                            </div>
                        </div>

                        <div className="job-details-content-2-block-left-title">
                            <FormattedMessage id="app.job_details.content_2.job_company"
                                defaultMessage="Find Jobs by Expertise"
                                description="Find Jobs by Expertise"
                                values={{what: 'react-intl'}}
                            />
                            <div className="job-details-content-2-block-left-content" dangerouslySetInnerHTML={ {__html: company.about_company} }></div>
                        </div>

                        <div className="job-details-content-2-block-left-title">
                            <FormattedMessage id="app.job_details.content_2.job_benefit"
                                defaultMessage="Job Benefit"
                                description="Job Benefit"
                                values={{what: 'react-intl'}}
                            />
                            {job.custom_fields?
                                <div className="job-details-content-2-block-left-content" dangerouslySetInnerHTML={ {__html: getJobCustomFields(job.custom_fields, "benefits")} }></div>
                                : null
                            }
                        </div>

                        <div className='job-details-right-wrapper-mobile'>
                            <div className='job-details-block-right-title '>
                                <FormattedMessage id="app.job_details.content_2.job_skillset"
                                    defaultMessage="Job Skillset"
                                    description="Job Skillset"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className='job-details-skillset-details'>

                            </div>

                            <div className='job-details-divide'></div>

                            <div className='job-details-block-right-title '>
                                <FormattedMessage id="app.job_details.content_2.job_summary"
                                    defaultMessage="Job Summary"
                                    description="Job Summary"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className='job-details-block-right-subtitle'>
                                <FormattedMessage id="app.job_details.content_2.specialisation"
                                    defaultMessage="Job specialisation"
                                    description="Job specialisation"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='job-details-block-right-subdetails'>
                                {/* {job.specialization} */}
                                {job.custom_fields?
                                    getJobCustomFields(job.custom_fields, "specialization")
                                : null
                                }
                            </div>

                            <div className='job-details-block-right-subtitle'>
                                <FormattedMessage id="app.job_details.content_2.industry"
                                    defaultMessage="Job industry"
                                    description="Job industry"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='job-details-block-right-subdetails'>
                                {job.custom_fields?
                                    getJobCustomFields(job.custom_fields, "industry")
                                : null
                                }
                            </div>

                            <div className='job-details-block-right-subtitle'>
                                <FormattedMessage id="app.job_details.content_2.location"
                                    defaultMessage="Job location"
                                    description="Job location"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='job-details-block-right-subdetails'>
                                {job.city}
                            </div>

                            <div className='job-details-block-right-subtitle'>
                                <FormattedMessage id="app.job_details.content_2.type"
                                    defaultMessage="Job type"
                                    description="Job type"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='job-details-block-right-subdetails'>
                                {job.custom_fields?
                                    getJobCustomFields(job.custom_fields, "jobtype")
                                : null
                                }
                            </div>

                            <div className='job-details-block-right-subtitle'>
                                <FormattedMessage id="app.job_details.content_2.reference"
                                    defaultMessage="Job reference"
                                    description="Job reference"
                                    values={{what: 'react-intl'}}
                                />
                            </div>
                            <div className='job-details-block-right-subdetails'>
                                {job.job_code}
                            </div>
                        </div>

                        <div className='job-details-content-2-button-wrapper job-details-content-2-button-wrapper-mobile'>
                            <div className="job-details-button job-details-apply-button">
                                <a href={job.application_form_url} target="_blank" className='job-details-button-link'>
                                    <FormattedMessage id="app.job_details.content_2.apply"
                                        defaultMessage="Job Benefit"
                                        description="Job Benefit"
                                        values={{what: 'react-intl'}}
                                    />
                                </a>
                            </div>

                            <div className="job-details-button job-details-copy-button" onClick={() => copyLink(job.application_form_url)}>
                                <div className='job-details-copy-link'>
                                    <FormattedMessage id="app.job_details.content_2.copylink"
                                        defaultMessage="Job Benefit"
                                        description="Job Benefit"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            :
                <>
                    <div className='header-background'>
                        <img src={HeaderBg} alt="job-header-background" className="job-header-bg"/>
                        <img className="jobs-header-logo" src={HeaderLogo} alt="job-header-logo"/>
                    </div>
                    <div className='content-wrapper'>
                        <div className='jobs-content-1'>
                            <div className='jobs-title'>
                                <FormattedMessage id="app.jobs.title"
                                    defaultMessage="Find Jobs by Expertise"
                                    description="Find Jobs by Expertise"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <ul className='jobs-hashtags-wrapper' ref={hashTags}>
                                {hashtags.map((tag, index) => {
                                    return (
                                        <li className="hashtag" key={tag.tagName} onClick={(e) => selectTag(e, tag)}>{tag.tagName}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className='content-wrapper job-details-content-2'>
                        <div className='job-details-block-left'>
                            <Link to="/jobs" className="job-details-content-2-link">
                                <FormattedMessage id="app.job_details.content_2.link"
                                    defaultMessage="Find Jobs by Expertise"
                                    description="Find Jobs by Expertise"
                                    values={{what: 'react-intl'}}
                                />
                            </Link>
                            <div className="job-details-content-2-title">
                                {job.name}
                            </div>

                            <div className="job-details-content-2-block-left-title">
                                <FormattedMessage id="app.job_details.content_2.job_description"
                                    defaultMessage="Find Jobs by Expertise"
                                    description="Find Jobs by Expertise"
                                    values={{what: 'react-intl'}}
                                />
                            </div>

                            <div className="job-details-content-2-description">
                                <div className="job-details-content-2-description-wrapper">
                                    <div className="job-details-content-2-block-left-content" dangerouslySetInnerHTML={ {__html: job.job_description_text} } />
                                </div>
                            </div>

                            <div className="job-details-content-2-block-left-title">
                                <FormattedMessage id="app.job_details.content_2.job_company"
                                    defaultMessage="Find Jobs by Expertise"
                                    description="Find Jobs by Expertise"
                                    values={{what: 'react-intl'}}
                                />
                                <div className="job-details-content-2-block-left-content" dangerouslySetInnerHTML={ {__html: company.about_company} }></div>
                            </div>

                            <div className="job-details-content-2-block-left-title">
                                <FormattedMessage id="app.job_details.content_2.job_benefit"
                                    defaultMessage="Job Benefit"
                                    description="Job Benefit"
                                    values={{what: 'react-intl'}}
                                />
                                {job.custom_fields?
                                    <div className="job-details-content-2-block-left-content" dangerouslySetInnerHTML={ {__html: getJobCustomFields(job.custom_fields, "benefits")} }></div>
                                    : null
                                }
                            </div>

                            <div className='job-details-content-2-button-wrapper'>
                                <div className="job-details-button job-details-apply-button">
                                    <a href={job.application_form_url} target="_blank" className='job-details-button-link'>
                                        <FormattedMessage id="app.job_details.content_2.apply"
                                            defaultMessage="Job Benefit"
                                            description="Job Benefit"
                                            values={{what: 'react-intl'}}
                                        />
                                    </a>
                                </div>

                                <div className="job-details-button job-details-copy-button" onClick={() => copyLink(window.location.href)}>
                                    <div className='job-details-copy-link'>
                                        <FormattedMessage id="app.job_details.content_2.copylink"
                                            defaultMessage="Job Benefit"
                                            description="Job Benefit"
                                            values={{what: 'react-intl'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='job-details-block-right'>
                            <div className='job-details-right-wrapper'>
                                <div className='job-details-block-right-title '>
                                    <FormattedMessage id="app.job_details.content_2.job_skillset"
                                        defaultMessage="Job Skillset"
                                        description="Job Skillset"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>

                                <div className='job-details-skillset-details'>

                                </div>

                                <div className='job-details-divide'></div>

                                <div className='job-details-block-right-title '>
                                    <FormattedMessage id="app.job_details.content_2.job_summary"
                                        defaultMessage="Job Summary"
                                        description="Job Summary"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>

                                <div className='job-details-block-right-subtitle'>
                                    <FormattedMessage id="app.job_details.content_2.specialisation"
                                        defaultMessage="Job specialisation"
                                        description="Job specialisation"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='job-details-block-right-subdetails'>
                                    {/* {job.specialization} */}
                                    {job.custom_fields?
                                        getJobCustomFields(job.custom_fields, "specialization")
                                    : null
                                    }
                                </div>

                                <div className='job-details-block-right-subtitle'>
                                    <FormattedMessage id="app.job_details.content_2.industry"
                                        defaultMessage="Job industry"
                                        description="Job industry"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='job-details-block-right-subdetails'>
                                    {job.custom_fields?
                                        getJobCustomFields(job.custom_fields, "industry")
                                    : null
                                    }
                                </div>

                                <div className='job-details-block-right-subtitle'>
                                    <FormattedMessage id="app.job_details.content_2.location"
                                        defaultMessage="Job location"
                                        description="Job location"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='job-details-block-right-subdetails'>
                                    {job.city}
                                </div>

                                <div className='job-details-block-right-subtitle'>
                                    <FormattedMessage id="app.job_details.content_2.type"
                                        defaultMessage="Job type"
                                        description="Job type"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='job-details-block-right-subdetails'>
                                    {job.custom_fields?
                                        getJobCustomFields(job.custom_fields, "jobtype")
                                    : null
                                    }
                                </div>

                                <div className='job-details-block-right-subtitle'>
                                    <FormattedMessage id="app.job_details.content_2.reference"
                                        defaultMessage="Job reference"
                                        description="Job reference"
                                        values={{what: 'react-intl'}}
                                    />
                                </div>
                                <div className='job-details-block-right-subdetails'>
                                    {job.job_code}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </main>
    );
}